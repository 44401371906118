import React, { useState } from "react";
import {
  Box,
  Text,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Divider,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";

const OmrEvaluationItem = ({ omrEvaluation }) => {
  // Define colors based on color mode
  const boxBg = useColorModeValue("white", "gray.800");
  const headerBg = useColorModeValue("blue.100", "blue.900");
  const studentBg = useColorModeValue("gray.100", "gray.700");
  const textColor = useColorModeValue("gray.800", "white");
  const subTextColor = useColorModeValue("gray.600", "gray.300");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const hoverBg = useColorModeValue("blue.50", "blue.800");
  const inputBg = useColorModeValue("gray.50", "gray.700");

  // State to manage visibility and search
  const [showStudents, setShowStudents] = useState(false);
  const [selectedStudentIndex, setSelectedStudentIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [studentSearchQuery, setStudentSearchQuery] = useState("");

  const handleOrgClick = () => {
    setShowStudents(!showStudents);
  };

  const handleStudentClick = (index) => {
    setSelectedStudentIndex(index === selectedStudentIndex ? null : index);
    setSearchQuery(""); // Reset question search query when a new student is selected
  };

  // Function to filter answers based on search query
  const filterAnswers = (answers) => {
    if (!searchQuery) return answers;
    return answers.filter((answer) =>
      answer.questionNumber
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
  };

  // Function to filter students based on name or roll number
  const filterStudents = (students) => {
    if (!studentSearchQuery) return students;
    return students.filter(
      (student) =>
        student.studentName
          .toLowerCase()
          .includes(studentSearchQuery.toLowerCase()) ||
        student.rollNumber
          .toLowerCase()
          .includes(studentSearchQuery.toLowerCase())
    );
  };

  return (
    <Box
      p={6}
      m={2}
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="lg"
      bg={boxBg}
      borderColor={borderColor}
    >
      <Box
        p={4}
        borderRadius="md"
        bg={headerBg}
        onClick={handleOrgClick}
        cursor="pointer"
        _hover={{ boxShadow: "md", bg: hoverBg, transform: "scale(1.02)" }}
        transition="all 0.2s"
      >
        <Text fontSize="xl" fontWeight="bold" color={textColor}>
          {omrEvaluation.orgName} - {omrEvaluation.classSectionName}
        </Text>
        <Text fontSize="md" color={subTextColor}>
          Subject: {omrEvaluation.subjectName}
        </Text>
        <Text fontSize="md" color={subTextColor}>
          Exam Date: {new Date(omrEvaluation.examDate).toLocaleDateString()}
        </Text>
      </Box>

      {showStudents && (
        <>
          <Input
            placeholder="Search by Student Name or Roll Number"
            value={studentSearchQuery}
            onChange={(e) => setStudentSearchQuery(e.target.value)}
            mt={4}
            mb={4}
            bg={inputBg}
            borderColor={borderColor}
          />
          <VStack align="start" spacing={4}>
            {filterStudents(omrEvaluation.studentsData).map(
              (student, index) => (
                <Box
                  key={index}
                  p={4}
                  borderWidth="1px"
                  borderRadius="lg"
                  width="100%"
                  bg={studentBg}
                  borderColor={borderColor}
                  cursor="pointer"
                  _hover={{
                    bg: hoverBg,
                    boxShadow: "md",
                    transform: "scale(1.02)",
                  }}
                  transition="all 0.2s"
                >
                  <Box onClick={() => handleStudentClick(index)}>
                    <Text fontSize="lg" fontWeight="bold" color={textColor}>
                      Student Name: {student.studentName}
                    </Text>
                    <Text fontSize="md" color={subTextColor}>
                      Father's Name: {student.fatherName}
                    </Text>
                    <Text fontSize="md" color={subTextColor}>
                      Roll Number: {student.rollNumber}
                    </Text>
                    <Text fontSize="md" color={subTextColor}>
                      OMR Number: {student.omrNumber}
                    </Text>
                  </Box>

                  {selectedStudentIndex === index && (
                    <>
                      <Divider my={4} />
                      <Input
                        placeholder="Search by Question Number"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        mb={4}
                        bg={inputBg}
                        borderColor={borderColor}
                      />
                      <Table variant="striped" colorScheme="blue" size="sm">
                        <Thead>
                          <Tr>
                            <Th>Question No.</Th>
                            <Th>Correct Answer</Th>
                            <Th>Student Answer</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {filterAnswers(student.answers).map((answer, i) => (
                            <Tr key={i}>
                              <Td>{answer.questionNumber}</Td>
                              <Td>{answer.correctAnswer || "N/A"}</Td>
                              <Td>{answer.studentAnswer || "N/A"}</Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </>
                  )}
                </Box>
              )
            )}
          </VStack>
        </>
      )}
    </Box>
  );
};

export default OmrEvaluationItem;
