import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Input,
  FormControl,
  Image,
  Text,
  useToast,
  VStack,
  Spinner,
  IconButton,
  HStack,
} from "@chakra-ui/react";
import { FiUpload, FiTrash2 } from "react-icons/fi";
import axios from "axios";
import renderMathInElement from "katex/contrib/auto-render";
import { hitBeApi } from "../../../api/api";
import { serverBaseUrl } from "../../../constants/Constants";
import { getCookieToken } from "../../../utils/utils";

const ImageQuestionProcessor = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [questionId, setQuestionId] = useState("");
  const [questionResponse, setQuestionResponse] = useState(null);
  const [crossQuestions, setCrossQuestions] = useState([]); // Changed to an array
  const [crossQuestion, setCrossQuestion] = useState(""); // New state for cross-question input
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCqSubmitted, setIsCqSubmitted] = useState(false);
  const toast = useToast();
  const fileInputRef = useRef();

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      setPreviewUrl(URL.createObjectURL(file));
      setIsSubmitted(false);
    }
  };

  const handleImageUpload = async () => {
    if (!selectedImage) {
      toast({
        title: "No image selected.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedImage);

    setLoading(true);
    try {
      const token = getCookieToken();
      const uploadResponse = await axios.post(
        `${serverBaseUrl}/file/upload/aws`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${token}`,
          },
        }
      );

      const imageUrl = uploadResponse?.data?.data?.fileUrl;

      const answerResponse = await hitBeApi("image/answer", "POST", {
        imageUrl,
      });

      setQuestionResponse(answerResponse.data.imageQuestionAnswer.response);
      setQuestionId(answerResponse.data.imageQuestionAnswer.questionId);
      setIsSubmitted(true);
      toast({
        title: "Image processed successfully.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error processing image.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    setPreviewUrl(null);
    setQuestionResponse(null);
    setCrossQuestion(""); // Clear cross-question input
    setCrossQuestions([]); // Clear cross-question history
    setIsSubmitted(false);
    fileInputRef.current.value = null;
  };

  const handleCrossQuestionChange = (event) => {
    setCrossQuestion(event.target.value);
  };

  const handleCrossQuestionSubmit = async () => {
    if (isCqSubmitted) {
      return;
    }
    if (!crossQuestion.trim()) {
      toast({
        title: "Cross-question cannot be empty.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    setIsCqSubmitted(true);

    try {
      const response = await hitBeApi("image/answer/question", "POST", {
        crossQuestionContent: crossQuestion,
        questionId: questionId,
      });

      const newCrossQuestion = {
        question: crossQuestion,
        response: response.data.crossQuestionAnswer, // Assuming response contains the answer
      };

      setCrossQuestions((prev) => [...prev, newCrossQuestion]); // Append new question and response
      setCrossQuestion(""); // Clear input after submission
      toast({
        title: "Cross-question processed successfully.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      setIsCqSubmitted(false);
    } catch (error) {
      toast({
        title: "Error processing cross-question.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const renderLatex = (text) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = text;
    renderMathInElement(tempDiv, {
      delimiters: [
        { left: "\\(", right: "\\)", display: false },
        { left: "\\[", right: "\\]", display: true },
      ],
    });
    return tempDiv.innerHTML;
  };

  const formatText = (text) => {
    const textStr = String(text);
    const formattedText = textStr
      .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>") // Bold text
      .replace(/\n/g, "<br>"); // New lines

    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = formattedText;
    renderMathInElement(tempDiv, {
      delimiters: [
        { left: "\\(", right: "\\)", display: false },
        { left: "\\[", right: "\\]", display: true },
      ],
    });
    return tempDiv.innerHTML;
  };

  return (
    <VStack spacing={4} align="center" marginTop={"5%"} marginBottom={"5%"}>
      <Text fontSize="xl" fontWeight="bold">
        Please upload 1 image file only
      </Text>
      <Text fontSize="md" color="gray.600">
        Image size should not exceed 10MB
      </Text>
      <Text fontSize="md" color="gray.600">
        Please wait for the response before refreshing
      </Text>

      <Box
        width={{ base: "80%", lg: "100%" }}
        maxW="500px"
        p={4}
        borderWidth={1}
        borderRadius="md"
        boxShadow="md"
        textAlign="center"
      >
        <FormControl>
          <Input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            onChange={handleImageChange}
            display="none"
          />
          <HStack justify="center">
            <IconButton
              icon={<FiUpload />}
              onClick={handleIconClick}
              colorScheme="teal"
              size="lg"
              aria-label="Upload Image"
              marginTop={2}
            />
            {previewUrl && (
              <IconButton
                icon={<FiTrash2 />}
                onClick={handleRemoveImage}
                colorScheme="red"
                size="lg"
                aria-label="Remove Image"
                marginTop={2}
              />
            )}
          </HStack>
        </FormControl>
        {previewUrl && (
          <Box mt={4}>
            <Image
              src={previewUrl}
              alt="Selected Question Image"
              maxW="100%"
              borderRadius="md"
            />
          </Box>
        )}
        <Button
          mt={4}
          colorScheme="teal"
          onClick={handleImageUpload}
          isLoading={loading}
          isDisabled={isSubmitted}
        >
          {loading ? <Spinner size="sm" /> : "Submit"}
        </Button>
      </Box>
      {questionResponse && (
        <Box
          mt={4}
          width="100%"
          maxW={{ base: "90%", lg: "80%" }}
          p={4}
          borderWidth={1}
          borderRadius="md"
          boxShadow="md"
        >
          <Text fontSize="md" fontWeight="bold" mb={2}>
            Response:
          </Text>
          <Text mb={4} color="teal.200">
            <span
              dangerouslySetInnerHTML={{
                __html: formatText(renderLatex(questionResponse)),
              }}
            />
          </Text>
        </Box>
      )}
      {isSubmitted && (
        <Box
          mt={4}
          width="100%"
          maxW={{ base: "100%", lg: "80%" }}
          p={4}
          borderWidth={1}
          borderRadius="md"
          boxShadow="md"
        >
          {crossQuestions.length > 0 && (
            <Box mt={4}>
              {crossQuestions.map((cq, index) => (
                <Box
                  key={index}
                  mb={4}
                  p={4}
                  borderWidth={1}
                  borderRadius="md"
                  boxShadow="md"
                >
                  <Text fontSize="md" fontWeight="bold" mb={2}>
                    Cross-Question {index + 1}:
                  </Text>
                  <Text fontSize="md" fontWeight="bold" color="teal.500" mb={2}>
                    Question:
                  </Text>
                  <Text mb={2} color="teal.300">
                    {cq.question}
                  </Text>
                  <Text fontSize="md" fontWeight="bold" color="teal.500" mb={2}>
                    Answer:
                  </Text>
                  <Text color="teal.200">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: formatText(renderLatex(cq.response)),
                      }}
                    />
                  </Text>
                </Box>
              ))}
            </Box>
          )}
          <FormControl>
            <Input
              value={crossQuestion}
              onChange={handleCrossQuestionChange}
              placeholder="Type your cross-question here..."
              mb={2}
            />
            <Button
              colorScheme="teal"
              onClick={handleCrossQuestionSubmit}
              mt="20px"
            >
              {isCqSubmitted ? <Spinner size="sm" /> : "Submit Cross-Question"}
            </Button>
          </FormControl>
        </Box>
      )}
    </VStack>
  );
};

export default ImageQuestionProcessor;
