import React from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";

const PrivacyPolicy = () => {
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const headingColor = useColorModeValue("gray.900", "whiteAlpha.900");
  const textColor = useColorModeValue("gray.700", "whiteAlpha.900");

  return (
    <Box
      py={20}
      px={8}
      bg={bgColor}
      id="privacy-policy"
      paddingTop={{ base: "25%", lg: "10%" }}
    >
      <VStack spacing={8} align="center">
        <Heading as="h1" size="2xl" mb={4} color={headingColor}>
          Privacy Policy
        </Heading>
        <Text fontSize="lg" maxW="800px" textAlign="left" color={textColor}>
          <strong>Welcome to TryExam AI!</strong>
          <br />
          This Privacy Policy explains how we collect, use, disclose, and
          safeguard your information when you use our website and services.
        </Text>
        <VStack spacing={6} align="start" maxW="800px" w="100%">
          <Box>
            <Heading as="h2" size="lg" mb={2} color={headingColor}>
              1. Information We Collect
            </Heading>
            <Text fontSize="md" color={textColor}>
              We collect personal information that you voluntarily provide to us
              when you register for an account, take a mock test, or contact us.
              This may include your name, email address, phone number, and any
              other information you choose to provide.
            </Text>
          </Box>
          <Box>
            <Heading as="h2" size="lg" mb={2} color={headingColor}>
              2. How We Use Your Information
            </Heading>
            <Text fontSize="md" color={textColor}>
              We use the information we collect to provide, maintain, and
              improve our services. This includes creating user accounts,
              generating mock tests, analyzing test results, and communicating
              with you.
            </Text>
          </Box>
          <Box>
            <Heading as="h2" size="lg" mb={2} color={headingColor}>
              3. How We Protect Your Information
            </Heading>
            <Text fontSize="md" color={textColor}>
              We implement a variety of security measures to maintain the safety
              of your personal information. These measures include encryption,
              secure servers, and regular security assessments.
            </Text>
          </Box>
          <Box>
            <Heading as="h2" size="lg" mb={2} color={headingColor}>
              4. Sharing Your Information
            </Heading>
            <Text fontSize="md" color={textColor}>
              We do not sell, trade, or otherwise transfer your personal
              information to outside parties except as required by law or to
              provide our services.
            </Text>
          </Box>
          <Box>
            <Heading as="h2" size="lg" mb={2} color={headingColor}>
              5. Cookies and Tracking Technologies
            </Heading>
            <Text fontSize="md" color={textColor}>
              We use cookies and other tracking technologies to enhance your
              experience on our website, analyze site traffic, and track user
              interactions.
            </Text>
          </Box>
          <Box>
            <Heading as="h2" size="lg" mb={2} color={headingColor}>
              6. Your Rights and Choices
            </Heading>
            <Text fontSize="md" color={textColor}>
              You have the right to access, update, or delete your personal
              information. You may also opt out of receiving promotional
              communications from us.
            </Text>
          </Box>
          <Box>
            <Heading as="h2" size="lg" mb={2} color={headingColor}>
              7. Changes to This Privacy Policy
            </Heading>
            <Text fontSize="md" color={textColor}>
              We may update this Privacy Policy from time to time. Any changes
              will be posted on this page, and you should review it
              periodically.
            </Text>
          </Box>
          <Box>
            <Heading as="h2" size="lg" mb={2} color={headingColor}>
              8. Contact Us
            </Heading>
            <Text fontSize="md" color={textColor}>
              If you have any questions about this Privacy Policy, please
              contact us at support@tryexamai.com.
            </Text>
          </Box>
        </VStack>
      </VStack>
    </Box>
  );
};

export default PrivacyPolicy;
