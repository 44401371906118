import { useSelector } from "react-redux";
import OmrEvaluationItem from "./OmrEvaluationItem";
import { VStack } from "@chakra-ui/react";

const OmrEvaluationList = () => {
  const userOmrUploads = useSelector((state) => state.userOmrUploads);

  return (
    <VStack spacing={2} align="stretch">
      {userOmrUploads?.map((omrEvaluation) => (
        <OmrEvaluationItem
          key={omrEvaluation.omrEvaluationId}
          omrEvaluation={omrEvaluation}
        />
      ))}
    </VStack>
  );
};

export default OmrEvaluationList;
