import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Stack,
  Avatar,
  useToast,
  IconButton,
  Spinner,
} from "@chakra-ui/react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { hitBeApi } from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getCookieToken } from "../../utils/utils";
import { serverBaseUrl } from "../../constants/Constants";

const Profile = () => {
  const [name, setName] = useState(""); // Ensure initial state is a string
  const [email, setEmail] = useState(""); // Ensure initial state is a string
  const [phone, setPhone] = useState(""); // Ensure initial state is a string
  const [profilePic, setProfilePic] = useState(null); // Can be null initially
  const [exams, setExams] = useState([""]); // Ensure initial state is an array with at least an empty string
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const profileDetailsFetched = useRef(false);
  const dispatch = useDispatch();
  const profileFromState = useSelector((state) => state.profile);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (profileDetailsFetched.current) return;
      profileDetailsFetched.current = true;
      try {
        const response = await hitBeApi("user", "GET");

        if (response) {
          dispatch({
            type: "SET_USER_ID",
            value: response.data.userDetails._id,
          });
          dispatch({
            type: "SET_PROFILE",
            value: response.data.userDetails,
          });

          setName(response.data.userDetails.name || "");
          setEmail(response.data.userDetails.email || "");
          setPhone(response.data.userDetails.phoneNumber || "");
          setExams(response.data.userDetails.examsPreparingFor || [""]);
          setProfilePic(response.data.userDetails.profilePicture || null);
        }
        toast({
          title: "Profile details retrieved successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: "Error.",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    if (profileFromState && Object.keys(profileFromState).length !== 0) {
      setName(profileFromState.name || "");
      setEmail(profileFromState.email || "");
      setPhone(profileFromState.phoneNumber || "");
      setExams(profileFromState.examsPreparingFor || [""]);
      setProfilePic(profileFromState.profilePicture || null);
    } else {
      fetchUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProfilePicChange = async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    setIsSubmitDisabled(true);

    try {
      setLoading(true);
      const token = getCookieToken();
      const response = await axios.post(
        `${serverBaseUrl}/file/upload/aws`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${token}`,
          },
        }
      );
      setProfilePic(response?.data?.data?.fileUrl);
      toast({
        title: "Profile picture uploaded successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsSubmitDisabled(false);
    } catch (error) {
      toast({
        title: "Error uploading profile picture.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAddExam = () => {
    setExams([...exams, ""]);
  };

  const handleExamChange = (index, value) => {
    const newExams = [...exams];
    newExams[index] = value;
    setExams(newExams);
  };

  const handleRemoveExam = (index) => {
    const newExams = [...exams];
    newExams.splice(index, 1);
    setExams(newExams);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitDisabled) {
      return;
    }
    try {
      const response = await hitBeApi("user", "PATCH", {
        name,
        email,
        phone,
        exams,
        profilePic,
      });

      setName(response.data.updatedUserDetails.name || "");
      setEmail(response.data.updatedUserDetails.email || "");
      setPhone(response.data.updatedUserDetails.phoneNumber || "");
      setExams(response.data.updatedUserDetails.examsPreparingFor || [""]);
      setProfilePic(response.data.updatedUserDetails.profilePicture || null);
      toast({
        title: "User details updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      py={2}
      px={8}
      minH="100vh"
      maxW={{ base: "100%", md: "75%", lg: "50%" }}
      mx="auto"
      mt={{ base: "2%", lg: "10%" }}
    >
      <Heading as="h2" size="lg" mb={6}>
        Profile
      </Heading>
      <Stack as="form" onSubmit={handleSubmit} spacing={4}>
        <FormControl isRequired>
          <FormLabel htmlFor="name">Name</FormLabel>
          <Input
            id="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your name"
          />
        </FormControl>
        <FormControl isDisabled>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
          />
        </FormControl>
        <FormControl isDisabled>
          <FormLabel htmlFor="phone">Phone</FormLabel>
          <Input
            id="phone"
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Enter your phone number"
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="profile-pic">Profile Picture</FormLabel>
          <Input
            id="profile-pic"
            type="file"
            accept="image/*"
            onChange={handleProfilePicChange}
          />
          {loading && <Spinner size="sm" />}
          {profilePic && (
            <Avatar
              src={profilePic}
              name={name}
              opacity={"1000"}
              zIndex={"1000"}
              size="xl"
              mt={4}
              transition="transform 0.2s"
              _hover={{ transform: "scale(4)" }}
            />
          )}
        </FormControl>
        <FormControl>
          <FormLabel>Exams I am preparing for</FormLabel>
          {exams.map((exam, index) => (
            <Box key={index} display="flex" alignItems="center" mb={2}>
              <Input
                type="text"
                value={exam}
                onChange={(e) => handleExamChange(index, e.target.value)}
                placeholder="Enter exam name"
                mr={2}
              />
              <IconButton
                aria-label="Remove exam"
                icon={<FaTrash />}
                colorScheme="red"
                onClick={() => handleRemoveExam(index)}
              />
            </Box>
          ))}
          <Button
            leftIcon={<FaPlus />}
            colorScheme="teal"
            onClick={handleAddExam}
            mt={2}
          >
            Add Exam
          </Button>
        </FormControl>
        <Button type="submit" colorScheme="blue" size="lg">
          {isSubmitDisabled ? <Spinner size="sm" /> : "Save"}
        </Button>
      </Stack>
    </Box>
  );
};

export default Profile;
