import React from "react";
import {
  Box,
  Heading,
  useColorModeValue,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  SimpleGrid,
} from "@chakra-ui/react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const AttemptsAnalytics = ({ attempts }) => {
  // Move all hook calls to the top level
  const bgColor = useColorModeValue("white", "gray.700");
  const textColor = useColorModeValue("black", "white");
  const headingColor = useColorModeValue("teal.600", "teal.300");
  const sectionTitleColor = useColorModeValue("teal.500", "teal.200");
  const statBgColor = useColorModeValue("gray.50", "gray.800");
  const statBorderColor = useColorModeValue("gray.200", "gray.600");

  const getTotalMarksObtained = (attempt) => {
    return (attempt?.sections || []).reduce((acc, section) => {
      return (
        acc +
        (section.questions || []).reduce(
          (sum, question) => sum + (question.userMarks || 0),
          0
        )
      );
    }, 0);
  };

  const getTotalMarksObtainedInPercentage = (attempt) => {
    const totalUserMarks = (attempt?.sections || []).reduce((acc, section) => {
      return (
        acc +
        (section.questions || []).reduce(
          (sum, question) => sum + (question.userMarks || 0),
          0
        )
      );
    }, 0);
    return ((totalUserMarks / attempt.totalMarks) * 100).toFixed(2);
  };

  const getMaximumMarksObtainedFromAll = (attempts) => {
    let maximumMarksPercentage = 0;
    attempts.forEach((attempt) => {
      const marksPercentage = getTotalMarksObtainedInPercentage(attempt);
      if (marksPercentage > maximumMarksPercentage) {
        maximumMarksPercentage = marksPercentage;
      }
    });
    return maximumMarksPercentage;
  };

  const getAverageMarksObtainedFromAll = (attempts) => {
    let averageMarksPercentage = 0;
    attempts.forEach((attempt) => {
      const marksPercentage = parseFloat(
        getTotalMarksObtainedInPercentage(attempt)
      ); // Ensure it's a number

      averageMarksPercentage += marksPercentage;
    });

    return averageMarksPercentage / attempts.length;
  };

  const getMinimumMarksObtainedFromAll = (attempts) => {
    let minimumMarksPercentage = 0;
    attempts.forEach((attempt) => {
      const marksPercentage = getTotalMarksObtainedInPercentage(attempt);
      if (marksPercentage < minimumMarksPercentage) {
        minimumMarksPercentage = marksPercentage;
      }
    });
    return minimumMarksPercentage;
  };

  const getTotalCorrectAnswers = (attempt) => {
    return (attempt?.sections || []).reduce((acc, section) => {
      return (
        acc +
        (section.questions || []).filter(
          (question) => question.answerStatus === "correct"
        ).length
      );
    }, 0);
  };

  const getTotalCorrectAnswersForAll = (attempts) => {
    let total = 0;
    attempts.forEach((attempt) => {
      total = total + getTotalCorrectAnswers(attempt);
    });
    return total;
  };

  const getTotalIncorrectAnswers = (attempt) => {
    return (attempt?.sections || []).reduce((acc, section) => {
      return (
        acc +
        (section.questions || []).filter(
          (question) => question.answerStatus === "inCorrect"
        ).length
      );
    }, 0);
  };

  const getTotalInCorrectAnswersForAll = (attempts) => {
    let total = 0;
    attempts.forEach((attempt) => {
      total = total + getTotalIncorrectAnswers(attempt);
    });
    return total;
  };

  const getTotalQuestions = (attempt) => {
    return (attempt?.sections || []).reduce((acc, section) => {
      return acc + (section.questions || []).length;
    }, 0);
  };

  const getTotalQuestionsFromAll = (attempts) => {
    let total = 0;
    attempts.forEach((attempt) => {
      total = total + getTotalQuestions(attempt);
    });
    return total;
  };

  const getTotalUnattemptedQuestions = (attempt) => {
    return (attempt?.sections || []).reduce((acc, section) => {
      return (
        acc +
        (section.questions || []).filter(
          (question) =>
            question.visibility === "notVisited" ||
            question.visibility === "attempted"
        ).length
      );
    }, 0);
  };

  const getTotalUnattemptedQuestionsForAll = (attempts) => {
    let total = 0;
    attempts.forEach((attempt) => {
      total = total + getTotalUnattemptedQuestions(attempt);
    });
    return total;
  };

  const labels = attempts.map((attempt) => attempt.title);

  const marksObtainedData = attempts.map((attempt) => {
    const totalMarks = getTotalMarksObtained(attempt);
    const totalQuestions = getTotalQuestions(attempt);
    return ((totalMarks / (totalQuestions * 10)) * 100).toFixed(2); // Adjust if necessary
  });

  const correctAnswersData = attempts.map((attempt) => {
    const correctAnswers = getTotalCorrectAnswers(attempt);
    const totalQuestions = getTotalQuestions(attempt);
    return ((correctAnswers / totalQuestions) * 100).toFixed(2);
  });

  const incorrectAnswersData = attempts.map((attempt) => {
    const incorrectAnswers = getTotalIncorrectAnswers(attempt);
    const totalQuestions = getTotalQuestions(attempt);
    return ((incorrectAnswers / totalQuestions) * 100).toFixed(2);
  });

  const unAttemptedQuestionsData = attempts.map((attempt) => {
    const incorrectAnswers = getTotalUnattemptedQuestions(attempt);
    const totalQuestions = getTotalQuestions(attempt);
    return ((incorrectAnswers / totalQuestions) * 100).toFixed(2);
  });

  const data = {
    labels,
    datasets: [
      {
        label: "% Marks Obtained",
        data: marksObtainedData.reverse(),
        borderColor: "rgba(54, 162, 235, 1)",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        fill: true,
      },
      {
        label: "% Correct Answers",
        data: correctAnswersData.reverse(),
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true,
      },
      {
        label: "% Incorrect Answers",
        data: incorrectAnswersData.reverse(),
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        fill: true,
      },
      {
        label: "% Unattempted Questions",
        data: unAttemptedQuestionsData.reverse(),
        borderColor: "rgba(150, 50, 132, 1)",
        backgroundColor: "rgba(150, 50, 132, 0.2)",
        fill: true,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.dataset.label + ": " + context.raw + "%";
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Exams",
        },
      },
      y: {
        title: {
          display: true,
          text: "Percentage (%)",
        },
        ticks: {
          callback: function (value) {
            return value + "%";
          },
        },
      },
    },
  };

  return (
    <Box
      pt={"1%"}
      textAlign="center"
      position="relative"
      my="10%"
      maxW={"90%"}
      mx="auto"
      p={4}
      borderRadius="lg"
      boxShadow="lg"
      bg={bgColor}
      color={textColor}
    >
      <Heading
        as="h1"
        mb={6}
        color={headingColor}
        fontSize={{ base: "large", lg: "xx-large" }}
      >
        Exam Attempts Analytics
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} mb={6}>
        <Stat
          p={4}
          border="1px"
          borderColor={statBorderColor}
          borderRadius="md"
          boxShadow="md"
          bg={statBgColor}
        >
          <StatLabel>Total Attempts</StatLabel>
          <StatNumber>{attempts.length}</StatNumber>
          <StatHelpText>Total number of mock tests attempted</StatHelpText>
        </Stat>
        <Stat
          p={4}
          border="1px"
          borderColor={statBorderColor}
          borderRadius="md"
          boxShadow="md"
          bg={statBgColor}
        >
          <StatLabel>Maximum Marks %</StatLabel>
          <StatNumber>{getMaximumMarksObtainedFromAll(attempts)}</StatNumber>
          <StatHelpText>
            Maximum % marks obtained across all attempts
          </StatHelpText>
        </Stat>
        <Stat
          p={4}
          border="1px"
          borderColor={statBorderColor}
          borderRadius="md"
          boxShadow="md"
          bg={statBgColor}
        >
          <StatLabel>Average Marks %</StatLabel>
          <StatNumber>{getAverageMarksObtainedFromAll(attempts)}</StatNumber>
          <StatHelpText>
            Average % marks obtained across all attempts
          </StatHelpText>
        </Stat>
        <Stat
          p={4}
          border="1px"
          borderColor={statBorderColor}
          borderRadius="md"
          boxShadow="md"
          bg={statBgColor}
        >
          <StatLabel>Minimum Marks %</StatLabel>
          <StatNumber>{getMinimumMarksObtainedFromAll(attempts)}</StatNumber>
          <StatHelpText>
            Minimum % marks obtained across all attempts
          </StatHelpText>
        </Stat>
        <Stat
          p={4}
          border="1px"
          borderColor={statBorderColor}
          borderRadius="md"
          boxShadow="md"
          bg={statBgColor}
        >
          <StatLabel>Average Correct Answers</StatLabel>
          <StatNumber>
            {(
              (getTotalCorrectAnswersForAll(attempts) /
                getTotalQuestionsFromAll(attempts)) *
              100
            ).toFixed(2)}
            %
          </StatNumber>
          <StatHelpText>% of correct answers across all attempts</StatHelpText>
        </Stat>
        <Stat
          p={4}
          border="1px"
          borderColor={statBorderColor}
          borderRadius="md"
          boxShadow="md"
          bg={statBgColor}
        >
          <StatLabel>Average Incorrect Answers</StatLabel>
          <StatNumber>
            {(
              (getTotalInCorrectAnswersForAll(attempts) /
                getTotalQuestionsFromAll(attempts)) *
              100
            ).toFixed(2)}
            %
          </StatNumber>
          <StatHelpText>
            % of incorrect answers across all attempts
          </StatHelpText>
        </Stat>
        <Stat
          p={4}
          border="1px"
          borderColor={statBorderColor}
          borderRadius="md"
          boxShadow="md"
          bg={statBgColor}
        >
          <StatLabel>Average Unattempted Questions</StatLabel>
          <StatNumber>
            {(
              (getTotalUnattemptedQuestionsForAll(attempts) /
                getTotalQuestionsFromAll(attempts)) *
              100
            ).toFixed(2)}
            %
          </StatNumber>
          <StatHelpText>
            % of questions not attempted across all attempts
          </StatHelpText>
        </Stat>
      </SimpleGrid>

      <Box mb={6}>
        <Heading as="h2" mb={4} fontSize="xl" color={sectionTitleColor}>
          Performance Over Time
        </Heading>
        <Line data={data} options={options} height={400} />
      </Box>
    </Box>
  );
};

export default AttemptsAnalytics;
