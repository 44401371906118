// Get cookie token
export const getCookieToken = () => {
  const cookieString = document.cookie;
  if (!cookieString) {
    return "";
  }
  const token = cookieString
    .split("; ")
    .find((row) => row.startsWith("token="))
    ?.split("=")[1];
  return token;
};

// Check if a cookie is expired
export const isCookieExpired = (cookieName) => {
  const cookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith(cookieName));
  if (!cookie) {
    return true;
  }
  const [, value] = cookie.split("=");
  const expirationDate = new Date(value);
  return expirationDate < new Date();
};

export const formatDate = (date) => {
  const d = new Date(date);
  const day = d.getDate();
  const daySuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // Special case for 11-20
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = monthNames[d.getMonth()];
  const year = d.getFullYear();

  return `${day}${daySuffix(day)} ${month} ${year}`;
};

export const getFormattedTime = (timeMilliSeconds) => {
  const hours = Math.floor(timeMilliSeconds / 3600000);
  const minutes = Math.floor((timeMilliSeconds % 3600000) / 60000);
  const seconds = Math.floor((timeMilliSeconds % 60000) / 1000);

  if (hours > 0) {
    return `${hours}h ${minutes}m ${seconds}s`;
  } else {
    return `${minutes}m ${seconds}s`;
  }
};

export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function formatDateStringIST(dateString) {
  const date = new Date(dateString);

  // Convert to IST (Indian Standard Time)
  const offset = 5.5 * 60 * 60 * 1000; // IST is UTC + 5:30
  const dateIST = new Date(date.getTime() + offset);

  const day = dateIST.getUTCDate();
  const month = dateIST.toLocaleString("default", { month: "long" });
  const year = dateIST.getUTCFullYear().toString().slice(-2);

  let hours = dateIST.getUTCHours();
  const minutes = dateIST.getUTCMinutes().toString().padStart(2, "0");
  const period = hours >= 12 ? "PM" : "AM";

  if (hours > 12) {
    hours -= 12;
  } else if (hours === 0) {
    hours = 12;
  }

  const nth = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return `${day}${nth(day)} ${month} ${year}, ${hours}.${minutes}${period}`;
}
