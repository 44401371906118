import React from "react";

const NotFound = () => {
  return (
    <div className="not-found-container">
      <style>{`
        .not-found-container {
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            background: #f8f9fa;
            font-family: 'Arial', sans-serif;
            overflow: hidden;
        }

        .not-found-content {
            text-align: center;
        }

        .glitch {
            position: relative;
            font-size: 10vw;
            color: #0f0;
            animation: glitch 1s infinite;
        }

        .glitch::before,
        .glitch::after {
            content: '404';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: inherit;
        }

        .glitch::before {
            left: 2px;
            text-shadow: -2px 0 red;
            clip: rect(24px, 550px, 90px, 0);
            animation: glitch-anim 2.5s infinite linear alternate-reverse;
        }

        .glitch::after {
            left: -2px;
            text-shadow: -2px 0 blue;
            clip: rect(85px, 550px, 140px, 0);
            animation: glitch-anim2 3s infinite linear alternate-reverse;
        }

        @keyframes glitch {
            0% {
                transform: translate(0);
            }
            20% {
                transform: translate(-2px, 2px);
            }
            40% {
                transform: translate(-2px, -2px);
            }
            60% {
                transform: translate(2px, 2px);
            }
            80% {
                transform: translate(2px, -2px);
            }
            100% {
                transform: translate(0);
            }
        }

        @keyframes glitch-anim {
            0% {
                clip: rect(42px, 9999px, 44px, 0);
            }
            5% {
                clip: rect(12px, 9999px, 19px, 0);
            }
            10% {
                clip: rect(57px, 9999px, 55px, 0);
            }
            15% {
                clip: rect(77px, 9999px, 65px, 0);
            }
            20% {
                clip: rect(11px, 9999px, 13px, 0);
            }
            25% {
                clip: rect(41px, 9999px, 30px, 0);
            }
            30% {
                clip: rect(97px, 9999px, 98px, 0);
            }
            35% {
                clip: rect(87px, 9999px, 77px, 0);
            }
            40% {
                clip: rect(47px, 9999px, 50px, 0);
            }
            45% {
                clip: rect(73px, 9999px, 85px, 0);
            }
            50% {
                clip: rect(19px, 9999px, 13px, 0);
            }
            55% {
                clip: rect(81px, 9999px, 99px, 0);
            }
            60% {
                clip: rect(39px, 9999px, 54px, 0);
            }
            65% {
                clip: rect(85px, 9999px, 74px, 0);
            }
            70% {
                clip: rect(63px, 9999px, 69px, 0);
            }
            75% {
                clip: rect(79px, 9999px, 80px, 0);
            }
            80% {
                clip: rect(9px, 9999px, 18px, 0);
            }
            85% {
                clip: rect(49px, 9999px, 51px, 0);
            }
            90% {
                clip: rect(89px, 9999px, 89px, 0);
            }
            95% {
                clip: rect(77px, 9999px, 78px, 0);
            }
            100% {
                clip: rect(24px, 9999px, 44px, 0);
            }
        }

        @keyframes glitch-anim2 {
            0% {
                clip: rect(9px, 9999px, 18px, 0);
            }
            5% {
                clip: rect(47px, 9999px, 50px, 0);
            }
            10% {
                clip: rect(73px, 9999px, 85px, 0);
            }
            15% {
                clip: rect(19px, 9999px, 13px, 0);
            }
            20% {
                clip: rect(81px, 9999px, 99px, 0);
            }
            25% {
                clip: rect(39px, 9999px, 54px, 0);
            }
            30% {
                clip: rect(85px, 9999px, 74px, 0);
            }
            35% {
                clip: rect(63px, 9999px, 69px, 0);
            }
            40% {
                clip: rect(79px, 9999px, 80px, 0);
            }
            45% {
                clip: rect(49px, 9999px, 51px, 0);
            }
            50% {
                clip: rect(89px, 9999px, 89px, 0);
            }
            55% {
                clip: rect(77px, 9999px, 78px, 0);
            }
            60% {
                clip: rect(9px, 9999px, 18px, 0);
            }
            65% {
                clip: rect(47px, 9999px, 50px, 0);
            }
            70% {
                clip: rect(73px, 9999px, 85px, 0);
            }
            75% {
                clip: rect(19px, 9999px, 13px, 0);
            }
            80% {
                clip: rect(81px, 9999px, 99px, 0);
            }
            85% {
                clip: rect(39px, 9999px, 54px, 0);
            }
            90% {
                clip: rect(85px, 9999px, 74px, 0);
            }
            95% {
                clip: rect(63px, 9999px, 69px, 0);
            }
            100% {
                clip: rect(79px, 9999px, 80px, 0);
            }
        }

        .message {
            font-size: 1.5em;
            color: #333;
            margin-bottom: 20px;
            animation: fadeIn 2s ease-in-out;
        }

        @keyframes fadeIn {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }

        .home-button {
            margin-top: 40px;
            padding: 10px 20px;
            font-size: 1em;
            text-decoration: none;
            color: #fff;
            background-color: #007bff;
            border: none;
            border-radius: 5px;
            animation: fadeInUp 2s ease-in-out;
        }

        @keyframes fadeInUp {
            0% {
                opacity: 0;
                transform: translateY(20px);
            }
            100% {
                opacity: 1;
                transform: translateY(0);
            }
        }

        .home-button:hover {
            background-color: #0056b3;
        }
      `}</style>
      <div className="not-found-content">
        <div className="glitch">404</div>
        <div className="message">
          Oops! The page you're looking for can't be found.
        </div>
        <a href="/" className="home-button">
          Go to Homepage
        </a>
      </div>
    </div>
  );
};

export default NotFound;
