import React from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";

const TermsAndConditions = () => {
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const headingColor = useColorModeValue("gray.900", "whiteAlpha.900");
  const textColor = useColorModeValue("gray.700", "whiteAlpha.900");

  return (
    <Box
      py={20}
      px={8}
      bg={bgColor}
      id="terms-and-conditions"
      paddingTop={{ base: "25%", lg: "10%" }}
    >
      <VStack spacing={8} align="center">
        <Heading as="h1" size="2xl" mb={4} color={headingColor}>
          Terms and Conditions
        </Heading>
        <Text fontSize="lg" maxW="800px" textAlign="left" color={textColor}>
          <strong>Welcome to TryExam AI!</strong>
          <br />
          These Terms and Conditions outline the rules and regulations for the
          use of TryExam AI's website and services.
        </Text>
        <VStack spacing={6} align="start" maxW="800px" w="100%">
          <Box>
            <Heading as="h2" size="lg" mb={2} color={headingColor}>
              1. Introduction
            </Heading>
            <Text fontSize="md" color={textColor}>
              By accessing or using our website and services, you agree to
              comply with and be bound by these Terms and Conditions.
            </Text>
          </Box>
          <Box>
            <Heading as="h2" size="lg" mb={2} color={headingColor}>
              2. Services
            </Heading>
            <Text fontSize="md" color={textColor}>
              We provide mock tests for various government exams, college
              entrance exams, and foreign entrance exams. Our services include
              AI-generated question papers, detailed analytics, and feedback.
            </Text>
          </Box>
          <Box>
            <Heading as="h2" size="lg" mb={2} color={headingColor}>
              3. User Responsibilities
            </Heading>
            <Text fontSize="md" color={textColor}>
              You agree to use our services only for lawful purposes and in
              accordance with our guidelines. You are responsible for
              maintaining the confidentiality of your account information.
            </Text>
          </Box>
          <Box>
            <Heading as="h2" size="lg" mb={2} color={headingColor}>
              4. Intellectual Property
            </Heading>
            <Text fontSize="md" color={textColor}>
              All content on our website, including text, graphics, logos, and
              images, is the property of TryExam AI and is protected by
              intellectual property laws.
            </Text>
          </Box>
          <Box>
            <Heading as="h2" size="lg" mb={2} color={headingColor}>
              5. Termination
            </Heading>
            <Text fontSize="md" color={textColor}>
              We reserve the right to terminate or suspend your access to our
              services if you violate these Terms and Conditions.
            </Text>
          </Box>
          <Box>
            <Heading as="h2" size="lg" mb={2} color={headingColor}>
              6. Limitation of Liability
            </Heading>
            <Text fontSize="md" color={textColor}>
              Our liability for any claims arising out of your use of our
              services is limited to the maximum extent permitted by law.
            </Text>
          </Box>
          <Box>
            <Heading as="h2" size="lg" mb={2} color={headingColor}>
              7. Changes to Terms
            </Heading>
            <Text fontSize="md" color={textColor}>
              We may update these Terms and Conditions from time to time. Your
              continued use of our services signifies your acceptance of any
              changes.
            </Text>
          </Box>
          <Box>
            <Heading as="h2" size="lg" mb={2} color={headingColor}>
              8. Governing Law
            </Heading>
            <Text fontSize="md" color={textColor}>
              These Terms and Conditions are governed by and construed in
              accordance with the laws of the jurisdiction in which TryExam AI
              operates.
            </Text>
          </Box>
          <Box>
            <Heading as="h2" size="lg" mb={2} color={headingColor}>
              9. Contact Information
            </Heading>
            <Text fontSize="md" color={textColor}>
              If you have any questions about these Terms and Conditions, please
              contact us at support@tryexamai.com.
            </Text>
          </Box>
        </VStack>
      </VStack>
    </Box>
  );
};

export default TermsAndConditions;
