import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  Flex,
  Spacer,
  Input,
  Select,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { formatDate } from "../../../utils/utils";
import { useSelector } from "react-redux";
import ViewAttempt from "./ViewAttempt";

const AllAttemptsDone = () => {
  const attemptsFromState = useSelector((state) => state.attempts);
  const [searchColumn, setSearchColumn] = useState("title");
  const [searchValue, setSearchValue] = useState("");
  const [filteredExamAttempts, setFilteredExamAttempts] =
    useState(attemptsFromState);
  const [selectedExamAttempt, setSelectedExamAttempt] = useState(null);

  const bg = useColorModeValue("gray.50", "gray.800");
  const color = useColorModeValue("black", "white");
  const boxBg = useColorModeValue("white", "gray.700");

  useEffect(() => {
    const lowercasedFilter = searchValue.toLowerCase();
    const newFilteredExams = attemptsFromState.filter((exam) => {
      if (searchColumn === "title") {
        return exam.title?.toLowerCase().includes(lowercasedFilter);
      } else if (searchColumn === "createdAt") {
        return new Date(exam.createdAt)
          .toLocaleDateString()
          .includes(lowercasedFilter);
      }
      return true;
    });
    setFilteredExamAttempts(newFilteredExams);
  }, [searchColumn, searchValue, attemptsFromState]);

  const handleViewAttempt = (exam) => {
    setSelectedExamAttempt(exam);
  };

  const calculateUserMarks = (sections) => {
    const totalMarks = sections.reduce((acc, section) => {
      const sectionMarks = section.questions.reduce(
        (sum, question) => sum + (question.userMarks || 0),
        0
      );

      return acc + sectionMarks;
    }, 0);

    return Math.floor(totalMarks);
  };

  const calculateTotalQuestions = (sections) => {
    return sections.reduce((total, section) => {
      return total + (section.questions ? section.questions.length : 0);
    }, 0);
  };

  return (
    <Box
      bg={bg}
      px={{ base: 2, md: 12, lg: 6 }}
      borderRadius="md"
      shadow="md"
      pt={4}
      pb={4}
      mt={{ base: 4, lg: 0 }}
    >
      {selectedExamAttempt ? (
        <ViewAttempt attemptId={selectedExamAttempt._id} />
      ) : (
        <>
          <Heading as="h2" size={{ base: "sm", lg: "lg" }} mb={6} mt={2}>
            All Mock Timed Tests Attempted so far
          </Heading>
          <Flex mb={4} gap={{ base: 1, lg: 4 }}>
            <Select
              value={searchColumn}
              onChange={(e) => setSearchColumn(e.target.value)}
              width="200px"
            >
              <option value="title">Title</option>
              <option value="createdAt">Attempt Date</option>
            </Select>
            <Input
              placeholder="Search..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </Flex>

          <VStack spacing={4} align="stretch" overflowY={"scroll"}>
            {filteredExamAttempts.length === 0 && (
              <Text
                fontSize="large"
                fontWeight="bold"
                mt={{ base: 2, lg: 4 }}
                mx="auto"
                px="5%"
              >
                No attempts found
              </Text>
            )}
            {filteredExamAttempts.map((attempt, index) => (
              <Box
                key={index}
                p={4}
                bg={boxBg}
                borderRadius="md"
                shadow="sm"
                _hover={{ shadow: "md" }}
              >
                <Flex alignItems="center">
                  <Box>
                    <Text
                      fontSize={{ base: "sm", lg: "lg" }}
                      fontWeight="bold"
                      color={color}
                    >
                      {attempt?.examDetails?.title}
                    </Text>
                    <Text color={color} fontSize={{ base: "xsm", lg: "lg" }}>
                      Attempted on: {formatDate(attempt.createdAt)}
                    </Text>
                    <Text color={color} fontSize={{ base: "xsm", lg: "lg" }}>
                      Marks: {calculateUserMarks(attempt?.sections)} /{" "}
                      {attempt?.examDetails?.totalMarks}
                    </Text>
                    <Text color={color} fontSize={{ base: "xsm", lg: "lg" }}>
                      Total Questions:{" "}
                      {calculateTotalQuestions(attempt?.sections)}
                    </Text>
                  </Box>
                  <Spacer />
                  <Button
                    colorScheme="blue"
                    onClick={() => handleViewAttempt(attempt)}
                    size={{ base: "sm", lg: "lg" }}
                    fontSize={"x-small"}
                  >
                    View Attempt
                  </Button>
                </Flex>
              </Box>
            ))}
          </VStack>
        </>
      )}
    </Box>
  );
};

export default AllAttemptsDone;
