export const appState = {
  exam: {},
  attempt: {},
  attempts: [],
  attemptsMap: {},
  profile: {},
  analytics: {},
  examId: "",
  userId: "",
  attemptId: "",
  allQuestionsMap: {},
  currentQuestion: {},
  currentQuestionNumber: 0,
  currentSectionNumber: 0,
  score: 0,
  userVoiceConversations: {},
  userPdfUploadQAs: {},
  userOmrUploads: [],
  userImageAskData: {},
};
