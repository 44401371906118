import React from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaSignInAlt, FaSearch, FaEdit, FaChartBar } from "react-icons/fa";

const Step = ({ icon, title, description }) => {
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <VStack spacing={4} align="center" textAlign="center">
      <Icon as={icon} w={10} h={10} color="teal.500" />
      <Heading as="h3" size="md" color={textColor}>
        {title}
      </Heading>
      <Text fontSize="md" color={textColor}>
        {description}
      </Text>
    </VStack>
  );
};

const HowItWorks = () => {
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const headingColor = useColorModeValue("gray.700", "white");

  return (
    <Box py={20} px={8} bg={bgColor} id="how-it-works">
      <VStack spacing={8} align="center">
        <Heading as="h2" size="xl" color={headingColor}>
          How It Works
        </Heading>
        <HStack spacing={10} wrap="wrap" justify="center">
          <Step
            icon={FaSignInAlt}
            title="1. Sign Up / Log In"
            description="Create an account or log in to get started with your mock tests."
          />
          <Step
            icon={FaSearch}
            title="2. Browse Exams"
            description="Write the exam pattern you want to practice for."
          />
          <Step
            icon={FaEdit}
            title="3. Take Mock Tests"
            description="Start taking mock tests with AI-generated questions tailored to your needs."
          />
          <Step
            icon={FaChartBar}
            title="4. Analyze Results"
            description="Review detailed analytics and feedback on your performance to improve."
          />
        </HStack>
      </VStack>
    </Box>
  );
};

export default HowItWorks;
