import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Heading,
  Button,
  VStack,
  Textarea,
  Input,
  Spinner,
  useToast,
  FormControl,
  FormLabel,
  Center,
  Text,
  Icon,
  ModalOverlay,
  ModalContent,
  Modal,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  RadioGroup,
  Radio,
  ModalFooter,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { hitBeApi } from "../../../api/api";
import { FaFileUpload } from "react-icons/fa";
import { getCookieToken } from "../../../utils/utils";
import { serverBaseUrl } from "../../../constants/Constants";

const NewMockTest = () => {
  const [examName, setExamName] = useState("");
  const [partDistribution, setPartDistribution] = useState("");
  const [questionTypeDistribution, setQuestionTypeDistribution] = useState("");
  const [difficultyDistribution, setDifficultyDistribution] = useState("");
  const [numQuestions, setNumQuestions] = useState("");
  const [examDuration, setExamDuration] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [waitMessage, setWaitMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null); // State for file upload
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const existingInProgressExamId = useSelector((state) => state.examId);
  const isOngoingExamDetailsApiCalled = useRef(false);
  const isFetchUploadedFilesApiCalled = useRef(false);
  const [existingUploadedFiles, setExistingUploadedFiles] = useState([]);
  const [selectedExistingFile, setSelectedExistingFile] = useState(null);
  const [examFileId, setExamFileId] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleSelectExistingFile = () => {
    onOpen(); // Open the modal to select an existing file
  };

  const handleConfirmSelection = () => {
    onClose();
  };

  const handleNumQuestionsChange = (e) => {
    if (e.target.value > 100) {
      toast({
        title: "Error",
        description: "Number of questions cannot be more than 100.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else {
      setNumQuestions(e.target.value);
    }
  };

  const handleExamDurationChange = (e) => {
    if (e.target.value > 180) {
      toast({
        title: "Error",
        description: "Exam duration cannot be more than 3 hours.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else {
      setExamDuration(e.target.value);
    }
  };

  useEffect(() => {
    const getOngoingExamDetails = async () => {
      if (isOngoingExamDetailsApiCalled.current) {
        return;
      }
      isOngoingExamDetailsApiCalled.current = true;
      try {
        const response = await hitBeApi("exams/ongoing", "GET");
        const { examDetails } = response.data;
        if (!examDetails || Object.keys(examDetails).length === 0) {
          toast({
            title: "Success",
            description: "No ongoing exam found, please generate a new exam.",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        } else {
          dispatch({
            type: "SET_EXAM_ID",
            value: examDetails?._id,
          });
          dispatch({
            type: "SET_EXAM",
            value: examDetails,
          });
          navigate("/dashboard/new-ai-mock-test/exam/start");
          toast({
            title: "Success",
            description: "Your ongoing exam details fetched successfully.",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error("Error fetching ongoing exam details:", error);
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    };

    if (existingInProgressExamId && existingInProgressExamId.length > 0) {
      navigate("/dashboard/new-ai-mock-test/exam/start");
    } else {
      getOngoingExamDetails();
    }
  }, [dispatch, existingInProgressExamId, navigate, toast]);

  useEffect(() => {
    const getAllExamFilesUploadedDetails = async () => {
      if (isFetchUploadedFilesApiCalled.current) {
        return;
      }
      isFetchUploadedFilesApiCalled.current = true;
      try {
        const response = await hitBeApi("file", "GET");
        setExistingUploadedFiles(response.data);
      } catch (error) {
        console.error("Error fetching uploaded files:", error);
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    };
    getAllExamFilesUploadedDetails();
  }, [toast]);

  const handleSubmit = async () => {
    if (!numQuestions || !examDuration) {
      toast({
        title: "Error",
        description: "Please fill all required fields",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    if (numQuestions < 0 || examDuration < 0) {
      toast({
        title: "Error",
        description: "Input field cannot be negative",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    const estimatedWaitTime = (numQuestions * 3) / 60;
    if (selectedFile) {
      setWaitMessage("Uploading file please wait...");
    }

    try {
      let fileId = null;
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile);

        const token = getCookieToken();
        const uploadResponse = await axios.post(
          `${serverBaseUrl}/pdf/images`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: `Bearer ${token}`,
            },
          }
        );

        const fileIdRes = uploadResponse?.data?.data?.fileWithImagesResponse;
        fileId = fileIdRes;
        setExamFileId(fileIdRes);
      }

      if (selectedExistingFile) {
        setExamFileId(selectedExistingFile?._id);
        fileId = selectedExistingFile?._id;
      }

      setWaitMessage(
        `Generating mock test..., Please wait for approximately ${estimatedWaitTime.toFixed(
          1
        )} minutes...`
      );

      const response = await hitBeApi("exams/do/generate", "POST", {
        query: `${examName.length ? `examName: ${examName}` : ""}`,
        examDesc: `${
          partDistribution.length > 0
            ? `partDistribution: ${partDistribution}`
            : ""
        },  ${
          questionTypeDistribution.length > 0
            ? `questionTypeDistribution: ${questionTypeDistribution}`
            : ""
        },  ${
          difficultyDistribution.length > 0
            ? `difficultyDistribution: ${difficultyDistribution}`
            : ""
        }`,
        numQuestions,
        examDuration,
        fileId: fileId || examFileId, // Include fileId in the request
      });

      dispatch({
        type: "SET_EXAM_ID",
        value: response?.data?.examId,
      });
      toast({
        title: "Exam generated successfully.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      navigate("/dashboard/new-ai-mock-test/exam/start");
    } catch (error) {
      toast({
        title: "Error generating exam.",
        description: error.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
      setWaitMessage("");
    }
  };

  return (
    <Box
      py={20}
      px={{ base: 0, lg: 8 }}
      minH="100vh"
      mx={{ base: "10%", lg: "0%" }}
    >
      <Box textAlign={"center"}>
        <Heading as="h2" size={{ base: "md", lg: "lg" }} mb={12}>
          Generate New AI Mock Test
        </Heading>
        <Center>
          <VStack spacing={4} width={{ base: "100%", lg: "50%" }}>
            {/* File Upload Section */}
            <FormControl>
              <FormLabel>
                Choose one of the following options to generate the exam:
              </FormLabel>

              {/* Option 1: Upload or Select Existing File */}
              <Box
                border="1px"
                borderColor={useColorModeValue("gray.200", "gray.600")}
                borderRadius="md"
                p={4}
                mb={4}
              >
                <FormLabel fontSize="lg" fontWeight="bold">
                  Option 1: Upload or Select Existing File
                </FormLabel>

                {existingUploadedFiles.length > 0 && (
                  <>
                    <Button
                      as="label"
                      htmlFor="fileUpload"
                      variant="outline"
                      leftIcon={<Icon as={FaFileUpload} />}
                      size="md"
                      height={{ base: "5vh", md: "5vh", lg: "5vh" }}
                      isDisabled={selectedExistingFile || examName}
                    >
                      Upload New File
                    </Button>

                    <Button
                      variant="outline"
                      size="md"
                      height={{ base: "5vh", md: "5vh", lg: "5vh" }}
                      ml={2}
                      onClick={handleSelectExistingFile}
                      isDisabled={selectedFile || examName}
                    >
                      Select Existing File
                    </Button>
                  </>
                )}

                {!existingUploadedFiles.length > 0 && (
                  <Button
                    as="label"
                    htmlFor="fileUpload"
                    variant="outline"
                    leftIcon={<Icon as={FaFileUpload} />}
                    size="md"
                    height={{ base: "5vh", md: "5vh", lg: "5vh" }}
                    isDisabled={selectedExistingFile || examName}
                  >
                    Upload New File
                  </Button>
                )}

                <Input
                  type="file"
                  id="fileUpload"
                  display="none"
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                  disabled={examName}
                />

                {selectedFile && (
                  <Text mt={2} fontSize="sm">
                    Selected File: {selectedFile.name}
                  </Text>
                )}

                {selectedExistingFile && (
                  <Text mt={2} fontSize="sm">
                    Selected Existing File: {selectedExistingFile.fileName}
                  </Text>
                )}

                {/* Modal for selecting an existing file */}
                <Modal isOpen={isOpen} onClose={onClose}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>Select an Existing File</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <RadioGroup
                        onChange={(value) =>
                          setSelectedExistingFile(JSON.parse(value))
                        }
                        value={JSON.stringify(selectedExistingFile)}
                      >
                        <VStack align="start">
                          {existingUploadedFiles.map((file, index) => (
                            <Radio key={index} value={JSON.stringify(file)}>
                              {file?.fileName}
                            </Radio>
                          ))}
                        </VStack>
                      </RadioGroup>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        colorScheme="blue"
                        mr={3}
                        onClick={handleConfirmSelection}
                      >
                        Confirm Selection
                      </Button>
                      <Button variant="ghost" onClick={onClose}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </Box>

              {/* Option 2: Enter Exam Name */}
              <Box
                border="1px"
                borderColor={useColorModeValue("gray.200", "gray.600")}
                borderRadius="md"
                p={4}
              >
                <FormLabel fontSize="lg" fontWeight="bold">
                  Option 2: Enter Exam Name
                </FormLabel>
                <Textarea
                  id="examName"
                  placeholder="Enter your exam name (e.g., 'UPSC')"
                  value={examName}
                  onChange={(e) => setExamName(e.target.value)}
                  height={{ base: "5vh", md: "5vh", lg: "5vh" }}
                  disabled={selectedFile || selectedExistingFile}
                />
              </Box>
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="partDistribution">
                Exam description (optional)
              </FormLabel>
              <Textarea
                id="partDistribution"
                placeholder={`1. For example: '50% questions from history, 20% from polity, and 30% from other subjects.'`}
                value={partDistribution}
                onChange={(e) => setPartDistribution(e.target.value)}
                height={{ base: "5vh", md: "5vh", lg: "5vh" }}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="questionTypeDistribution">
                Question Types (optional)
              </FormLabel>
              <Textarea
                id="questionTypeDistribution"
                placeholder={`2. For example: '70% multiple-choice questions, 20% descriptive questions, and 10% true/false questions.'`}
                value={questionTypeDistribution}
                onChange={(e) => setQuestionTypeDistribution(e.target.value)}
                height={{ base: "5vh", md: "5vh", lg: "5vh" }}
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="difficultyDistribution">
                Difficulty Level (optional)
              </FormLabel>
              <Textarea
                id="difficultyDistribution"
                placeholder={`3. For example: '40% easy, 40% medium, and 20% hard questions.'`}
                value={difficultyDistribution}
                onChange={(e) => setDifficultyDistribution(e.target.value)}
                height={{ base: "5vh", md: "5vh", lg: "5vh" }}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="numQuestions">Number of Questions</FormLabel>
              <Input
                type="number"
                id="numQuestions"
                placeholder="Number of Questions (max 100)"
                value={numQuestions}
                onChange={handleNumQuestionsChange}
                height={{ base: "5vh", md: "5vh", lg: "5vh" }}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor="examDuration">
                Exam Duration (minutes)
              </FormLabel>
              <Input
                type="number"
                id="examDuration"
                placeholder="Exam Duration (in minutes, max 180)"
                value={examDuration}
                onChange={handleExamDurationChange}
                height={{ base: "5vh", md: "5vh", lg: "5vh" }}
              />
            </FormControl>

            <Button
              colorScheme="blue"
              onClick={handleSubmit}
              isLoading={isLoading}
              height={{ base: "5vh", md: "5vh", lg: "5vh" }}
            >
              Generate Mock Test
            </Button>
            {isLoading && (
              <>
                <Text fontWeight="bold" fontSize="lg">
                  {waitMessage}
                </Text>
                <Spinner size="sm" />
              </>
            )}
          </VStack>
        </Center>
      </Box>
    </Box>
  );
};

export default NewMockTest;
