import React from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const CallToAction = () => {
  const bgColor = useColorModeValue("teal.500", "teal.300");
  const textColor = useColorModeValue("white", "gray.800");
  const buttonVariantColor = useColorModeValue("teal.50", "teal.700");

  return (
    <Box
      py={20}
      px={8}
      bg={bgColor}
      color={textColor}
      textAlign="center"
      id="call-to-action"
    >
      <VStack spacing={8}>
        <Heading as="h2" size="xl">
          Start Your Free Trial Today!
        </Heading>
        <Text fontSize="lg" maxW="2xl">
          Join TryExam AI and take the first step towards acing your exams with
          personalized ai tailored exam features, detailed analytics, and expert
          feedback. Sign up now and experience the difference!
        </Text>
        <Button
          colorScheme="teal"
          size="lg"
          variant="outline"
          color={buttonVariantColor}
        >
          <Link to="/login">Try AI MockTest</Link>
        </Button>
      </VStack>
    </Box>
  );
};

export default CallToAction;
