import React, { useState, useEffect, useRef } from "react";
import { Box, VStack, Text, Button, useToast } from "@chakra-ui/react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { useDispatch } from "react-redux";
import "katex/dist/katex.min.css";
import renderMathInElement from "katex/contrib/auto-render";
import { hitBeApi } from "../../../api/api";
import AIAvatar from "./AiAvatar";

const TalkToAssistantComponent = () => {
  const [currentLanguage, setCurrentLanguage] = useState("en-US"); // Default to English
  const [audio, setAudio] = useState(null); // State to store audio data

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const [utterance, setUtterance] = useState(null);
  const initialPromptTimeout = useRef(null);
  const debounceTimeout = useRef(null);
  const dispatch = useDispatch();
  const toast = useToast();
  const [isLoadingData, setIsLoadingData] = useState(false);

  useEffect(() => {
    if (!browserSupportsSpeechRecognition) {
      console.error("Browser does not support speech recognition.");
    } else {
      initialPromptTimeout.current = setTimeout(() => {
        if (audio) {
          audio.pause();
          setAudio(null);
        }
        speak(
          "Let's start the mock test. Which topic do you want to practice?"
        );
      }, 2000);
    }

    return () => {
      if (initialPromptTimeout.current) {
        clearTimeout(initialPromptTimeout.current);
      }
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      if (utterance) {
        window.speechSynthesis.cancel();
      }
      if (audio) {
        audio.pause();
        setAudio(null);
      }
      SpeechRecognition.abortListening();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [browserSupportsSpeechRecognition]);

  useEffect(() => {
    if (!listening && transcript) {
      debounceTimeout.current = setTimeout(() => {
        sendToApi(transcript);
      }, 1000); // Adjust the delay as needed
    }

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listening, transcript]);

  const startRecording = () => {
    if (audio) {
      audio.pause();
      setAudio(null);
    }
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    SpeechRecognition.startListening({
      continuous: false,
      language: currentLanguage,
    });
  };

  const stopRecording = () => {
    SpeechRecognition.stopListening();
    resetTranscript();
  };

  const detectLanguage = (text) => {
    const hindiPattern = /[\u0900-\u097F]/; // Hindi Unicode range
    return hindiPattern.test(text) ? "hi-IN" : "en-US";
  };

  const stopSpeakingAndStartListening = () => {
    if (utterance) {
      window.speechSynthesis.cancel();
    }
    if (audio) {
      audio.pause();
      setAudio(null);
    }
    startRecording();
  };

  const stopSpeaking = () => {
    if (utterance) {
      window.speechSynthesis.cancel();
    }
    if (audio) {
      audio.pause();
      setAudio(null);
    }
    SpeechRecognition.abortListening();
  };

  const sendToApi = async (text) => {
    if (isLoadingData || text.length === 0 || audio !== null) {
      return;
    }
    try {
      const detectedLanguage = detectLanguage(text);
      setCurrentLanguage(detectedLanguage);
      setIsLoadingData(true);

      const response = await hitBeApi(`chat/talk`, "POST", {
        text: text,
      });
      const responseText = response?.data?.messageContent;
      const responseAudio = response?.data?.audio;
      dispatch({
        type: "SET_VOICE_CONVERSATIONS",
        value: response?.data?.userDatewiseConversationsMap,
      });
      if (responseAudio) {
        setIsLoadingData(false);
        // Convert base64 to binary
        const binaryString = window.atob(responseAudio);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        // Create a Blob from the binary data
        const blob = new Blob([bytes.buffer], { type: "audio/mp3" });
        const audioUrl = URL.createObjectURL(blob);
        const audioElement = new Audio(audioUrl);
        setAudio(audioElement);
        audioElement.play();
        audioElement.addEventListener("ended", () => {
          setAudio(null);
        });
      } else {
        setIsLoadingData(false);
        await speak(responseText);
      }
    } catch (error) {
      setIsLoadingData(false);
      console.error("Error sending text to API: ", error);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const renderLatex = (text) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = text;
    renderMathInElement(tempDiv, {
      delimiters: [
        { left: "\\(", right: "\\)", display: false },
        { left: "\\[", right: "\\]", display: true },
      ],
    });
    return tempDiv.innerHTML;
  };

  const formatText = (text) => {
    const textStr = String(text);
    const formattedText = textStr
      .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>") // Bold text
      .replace(/\n/g, "<br>"); // New lines

    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = formattedText;
    renderMathInElement(tempDiv, {
      delimiters: [
        { left: "\\(", right: "\\)", display: false },
        { left: "\\[", right: "\\]", display: true },
      ],
    });
    return tempDiv.innerHTML;
  };

  const speak = async (text) => {
    stopRecording();
    const synth = window.speechSynthesis;
    if (utterance) {
      synth.cancel();
    }

    const renderedText = renderLatex(text); // LaTeX rendering
    const formattedText = formatText(renderedText); // Apply custom formatting
    const newUtterance = new SpeechSynthesisUtterance(formattedText);
    setUtterance(newUtterance);
    newUtterance.lang = currentLanguage;

    newUtterance.onend = () => {
      startRecording();
    };

    newUtterance.onstart = () => {
      if (listening) {
        stopRecording();
      }
    };

    synth.speak(newUtterance);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      my={{ base: "5%", lg: "5%" }}
      mx={{ base: "0%", lg: "auto" }}
      width={"100%"}
    >
      <VStack spacing={{ base: 2, lg: 4 }} position="relative">
        {(listening || isLoadingData) && (
          <Text
            position="absolute"
            top="10%"
            left="50%"
            transform="translate(-50%, -50%)"
            zIndex="1"
            color="white"
            bg="rgba(0, 0, 0, 0.5)"
            p={2}
            borderRadius="md"
            fontSize={{ base: "md", lg: "xl" }}
          >
            {listening ? "Listening..." : "Loading Response..."}
          </Text>
        )}
        <AIAvatar
          isSpeaking={audio !== null}
          blurAmount={listening || isLoadingData ? 5 : 0}
        />
        {transcript && (
          <Text mt={4} fontSize={{ base: "small", lg: "lg" }}>
            Transcript: {transcript}
          </Text>
        )}
        <Button
          onClick={startRecording}
          fontSize={{ base: "medium", lg: "lg" }}
          isDisabled={isLoadingData}
        >
          Restart Listening to me
        </Button>
        <Button
          onClick={stopSpeakingAndStartListening}
          fontSize={{ base: "medium", lg: "lg" }}
          isDisabled={isLoadingData}
        >
          Stop Speaking and Listen to me
        </Button>
        <Button
          onClick={stopSpeaking}
          fontSize={{ base: "medium", lg: "lg" }}
          isDisabled={isLoadingData}
        >
          Stop Speaking
        </Button>
      </VStack>
    </Box>
  );
};

export default TalkToAssistantComponent;
