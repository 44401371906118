import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Stack,
  Text,
  useToast,
  useColorModeValue,
  Spinner,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { hitBeApi } from "../../api/api";
import { isCookieExpired } from "../../utils/utils";

const countryCodes = [
  "+1",
  "+91",
  "+81",
  "+65",
  "+49",
  "+33",
  "+39",
  "+31",
  "+41",
  "+44",
];

const Register = () => {
  const [name, setName] = useState("");
  const [contactMethod, setContactMethod] = useState("phone"); // New state for contact method
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otpTimer, setOtpTimer] = useState(0);
  const toast = useToast();
  const navigate = useNavigate();
  const [isOtpResent, setIsOtpReSent] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChangeContactMethod = (e) => {
    const newContactMethod = e.target.value;
    setPhoneNumber("");
    setEmail("");
    setContactMethod(newContactMethod);
  };

  useEffect(() => {
    let timer;
    if (isOtpSent && otpTimer > 0) {
      timer = setTimeout(() => setOtpTimer(otpTimer - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [otpTimer, isOtpSent]);

  const handleOtpRequest = async () => {
    try {
      if (otpTimer > 0) {
        return;
      }
      setIsOtpReSent(true);
      const contactInfo =
        contactMethod === "phone"
          ? { phoneNumber: countryCode + phoneNumber }
          : { email };
      await hitBeApi(`${contactMethod}/otp`, "POST", contactInfo);
      setOtpTimer(60);
      toast({
        title: "OTP sent.",
        description: `Please check your ${
          contactMethod === "phone" ? "phone" : "email"
        } for the OTP.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsOtpReSent(false);
    } catch (error) {
      setIsOtpReSent(false);
      toast({
        title: "Error.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleOtpVerification = async () => {
    if (otp) {
      try {
        const contactInfo =
          contactMethod === "phone"
            ? { phoneNumber: countryCode + phoneNumber }
            : { email };
        const response = await hitBeApi(`${contactMethod}/otp/verify`, "POST", {
          ...contactInfo,
          otp: parseInt(otp),
        });

        if (response.success) {
          toast({
            title: "OTP verified.",
            description: "OTP verified successfully. Try logging in now!",
            status: "success",
            duration: 5000,
            isClosable: true,
          });

          navigate("/login");
        } else {
          toast({
            title: "Error",
            description: "Incorrect OTP.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Error.",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Error.",
        description: "Please enter the OTP.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSubmit = async (e) => {
    setIsSubmitted(true);
    e.preventDefault();
    if (password !== confirmPassword) {
      setIsSubmitted(false);
      toast({
        title: "Error.",
        description: "Passwords do not match.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (contactMethod === "phone" && phoneNumber.length !== 10) {
      setIsSubmitted(false);
      toast({
        title: "Error.",
        description: "Please enter a valid 10-digit phone number.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const registrationData =
        contactMethod === "phone"
          ? { name, phoneNumber: countryCode + phoneNumber, password }
          : { name, email, password };
      await hitBeApi("user/register", "POST", registrationData);
      await handleOtpRequest(); // Send OTP immediately after registration
      setIsSubmitted(false);
      toast({
        title: "Registered successfully.",
        description: "Registration completed. Please verify your OTP.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsOtpSent(true);
    } catch (error) {
      setIsSubmitted(false);
      toast({
        title: "Error.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (!isCookieExpired("token")) {
      toast({
        title: "Auto-Login Success.",
        description: "Login successful with browser cache.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const bgColor = useColorModeValue("gray.50", "gray.800");
  const boxBgColor = useColorModeValue("white", "gray.700");
  const headingColor = useColorModeValue("gray.900", "whiteAlpha.900");
  const textColor = useColorModeValue("gray.700", "whiteAlpha.900");

  return (
    <Box
      py={20}
      px={{ base: 4, lg: 8 }}
      bg={bgColor}
      minH="70vh"
      paddingTop={{ base: "25%", lg: "10%" }}
    >
      <Stack spacing={8} align="center">
        <Heading as="h1" size="2xl" mb={4} color={headingColor}>
          Register
        </Heading>
        <Box
          p={6}
          bg={boxBgColor}
          boxShadow="lg"
          borderRadius="md"
          maxW="400px"
          w="100%"
        >
          {!isOtpSent ? (
            <form onSubmit={handleSubmit}>
              <Stack spacing={4}>
                <FormControl isRequired>
                  <FormLabel htmlFor="name" color={textColor}>
                    Name
                  </FormLabel>
                  <Input
                    id="name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter your name"
                    color={textColor}
                    bg={boxBgColor}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="contactMethod" color={textColor}>
                    Register with
                  </FormLabel>
                  <Select
                    id="contactMethod"
                    value={contactMethod}
                    onChange={handleChangeContactMethod}
                    color={textColor}
                    bg={boxBgColor}
                  >
                    <option value="phone">Phone</option>
                    <option value="email">Email</option>
                  </Select>
                </FormControl>
                {contactMethod === "phone" ? (
                  <>
                    <FormControl isRequired>
                      <FormLabel htmlFor="countryCode" color={textColor}>
                        Country Code
                      </FormLabel>
                      <Select
                        id="countryCode"
                        value={countryCode}
                        onChange={(e) => setCountryCode(e.target.value)}
                        color={textColor}
                        bg={boxBgColor}
                      >
                        {countryCodes.map((code) => (
                          <option key={code} value={code}>
                            {code}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel htmlFor="phone" color={textColor}>
                        Phone Number
                      </FormLabel>
                      <Input
                        id="phone"
                        type="number"
                        value={phoneNumber}
                        onChange={(e) =>
                          setPhoneNumber(e.target.value.slice(0, 10))
                        }
                        placeholder="Enter your phone number"
                        color={textColor}
                        bg={boxBgColor}
                      />
                    </FormControl>
                  </>
                ) : (
                  <FormControl isRequired>
                    <FormLabel htmlFor="email" color={textColor}>
                      Email
                    </FormLabel>
                    <Input
                      id="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email"
                      color={textColor}
                      bg={boxBgColor}
                    />
                  </FormControl>
                )}
                <FormControl isRequired>
                  <FormLabel htmlFor="password" color={textColor}>
                    Password
                  </FormLabel>
                  <Input
                    id="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password"
                    color={textColor}
                    bg={boxBgColor}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="confirmPassword" color={textColor}>
                    Confirm Password
                  </FormLabel>
                  <Input
                    id="confirmPassword"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm your password"
                    color={textColor}
                    bg={boxBgColor}
                  />
                </FormControl>
                {isSubmitted ? (
                  <Spinner size="sm" mx={"auto"} />
                ) : (
                  <Button
                    type="submit"
                    colorScheme="blue"
                    isDisabled={isOtpSent}
                  >
                    Register
                  </Button>
                )}
              </Stack>
            </form>
          ) : (
            <Stack spacing={4}>
              <Text color={textColor} fontSize={{ base: "small", lg: "large" }}>
                An OTP has been sent to your{" "}
                {contactMethod === "phone" ? "phone" : "email"}{" "}
                {contactMethod === "phone" ? phoneNumber : email}
              </Text>
              <FormControl isRequired>
                <FormLabel htmlFor="otp" color={textColor}>
                  Enter OTP
                </FormLabel>
                <Input
                  id="otp"
                  type="number"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="Enter OTP"
                  color={textColor}
                  bg={boxBgColor}
                />
              </FormControl>
              <Button onClick={handleOtpVerification} colorScheme="blue">
                Verify OTP
              </Button>
              {otpTimer > 0 && (
                <Text color={textColor} textAlign="center">
                  Resend OTP in {otpTimer} seconds
                </Text>
              )}
              {isOtpResent ? (
                <Spinner size="sm" mx={"auto"} />
              ) : (
                <Button
                  onClick={handleOtpRequest}
                  colorScheme="blue"
                  isDisabled={otpTimer > 0}
                >
                  Resend OTP
                </Button>
              )}
            </Stack>
          )}
        </Box>
        <Text color={textColor}>
          Already have an account?{" "}
          <Link to="/login">
            <Button variant="link" colorScheme="teal">
              Login here
            </Button>
          </Link>
        </Text>
      </Stack>
    </Box>
  );
};

export default Register;
