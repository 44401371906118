import React from "react";
import {
  Box,
  Heading,
  Text,
  useColorModeValue,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  SimpleGrid,
  Spinner,
} from "@chakra-ui/react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const PdfEvaluationAnalytics = ({ evaluationResults }) => {
  // Move all hook calls to the top level
  const bgColor = useColorModeValue("white", "gray.700");
  const textColor = useColorModeValue("black", "white");
  const headingColor = useColorModeValue("teal.600", "teal.300");
  const sectionTitleColor = useColorModeValue("teal.500", "teal.200");
  const statBgColor = useColorModeValue("gray.50", "gray.800");
  const statBorderColor = useColorModeValue("gray.200", "gray.600");

  if (
    evaluationResults.length === 0 ||
    evaluationResults === null ||
    evaluationResults === undefined
  ) {
    return (
      <Box
        pt={"1%"}
        textAlign="center"
        position="relative"
        mt="10%"
        maxW={"80%"}
        mx="auto"
        p={4}
        borderRadius="lg"
        boxShadow="lg"
        bg={bgColor}
        color={textColor}
      >
        <Spinner
          size="xl"
          color={headingColor}
          thickness="4px"
          speed="0.65s"
          mt="20%"
        />
        <Text mt={4}>Loading your analytics...</Text>
      </Box>
    );
  }

  const getTotalQuestions = (evaluation) => {
    return evaluation.length;
  };

  const getTotalCorrectAnswers = (evaluation) => {
    return evaluation.filter((question) => question.answerStatus === "correct")
      .length;
  };

  const getTotalIncorrectAnswers = (evaluation) => {
    return evaluation.filter(
      (question) => question.answerStatus === "inCorrect"
    ).length;
  };

  const getTotalMarksObtained = (evaluation) => {
    return evaluation.reduce(
      (sum, question) => sum + (question.answerMarks || 0),
      0
    );
  };

  const getTotalMarksPossible = (evaluation) => {
    return evaluation.reduce(
      (sum, question) => sum + (question.questionTotalMarks || 0),
      0
    );
  };

  const getTotalUnattemptedQuestions = (evaluation) => {
    return evaluation.filter(
      (question) => question.answerStatus === "notEvaluated"
    ).length;
  };

  const labels = Object.keys(evaluationResults);

  const totalCorrectData = labels.map((key) => {
    const totalCorrect = getTotalCorrectAnswers(evaluationResults[key]);
    const totalQuestions = getTotalQuestions(evaluationResults[key]);
    return ((totalCorrect / totalQuestions) * 100).toFixed(2);
  });

  const totalIncorrectData = labels.map((key) => {
    const totalIncorrect = getTotalIncorrectAnswers(evaluationResults[key]);
    const totalQuestions = getTotalQuestions(evaluationResults[key]);
    return ((totalIncorrect / totalQuestions) * 100).toFixed(2);
  });

  const totalUnattemptedData = labels.map((key) => {
    const totalUnattempted = getTotalUnattemptedQuestions(
      evaluationResults[key]
    );
    const totalQuestions = getTotalQuestions(evaluationResults[key]);
    return ((totalUnattempted / totalQuestions) * 100).toFixed(2);
  });

  const totalMarksData = labels.map((key) => {
    const totalMarks = getTotalMarksObtained(evaluationResults[key]);
    const totalMarksPossible = getTotalMarksPossible(evaluationResults[key]);
    return ((totalMarks / totalMarksPossible) * 100).toFixed(2);
  });

  const data = {
    labels,
    datasets: [
      {
        label: "% Correct Answers",
        data: totalCorrectData,
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true,
      },
      {
        label: "% Incorrect Answers",
        data: totalIncorrectData,
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        fill: true,
      },
      {
        label: "% Unattempted Questions",
        data: totalUnattemptedData,
        borderColor: "rgba(153, 102, 255, 1)",
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        fill: true,
      },
      {
        label: "% Marks Obtained",
        data: totalMarksData,
        borderColor: "rgba(54, 162, 235, 1)",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        fill: true,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.dataset.label + ": " + context.raw + "%";
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Uploads",
        },
      },
      y: {
        title: {
          display: true,
          text: "Percentage (%)",
        },
        ticks: {
          callback: function (value) {
            return value + "%";
          },
        },
      },
    },
  };

  return (
    <Box
      pt={"1%"}
      textAlign="center"
      position="relative"
      my="10%"
      maxW={"90%"}
      mx="auto"
      p={4}
      borderRadius="lg"
      boxShadow="lg"
      bg={bgColor}
      color={textColor}
    >
      <Heading
        as="h1"
        mb={6}
        color={headingColor}
        fontSize={{ base: "large", lg: "xx-large" }}
      >
        PDF Evaluations Analytics
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} mb={6}>
        <Stat
          p={4}
          border="1px"
          borderColor={statBorderColor}
          borderRadius="md"
          boxShadow="md"
          bg={statBgColor}
        >
          <StatLabel>Total Uploads</StatLabel>
          <StatNumber>{labels.length}</StatNumber>
          <StatHelpText>Total number of PDF uploads</StatHelpText>
        </Stat>
      </SimpleGrid>

      <Box mb={6}>
        <Heading as="h2" mb={4} fontSize="xl" color={sectionTitleColor}>
          Performance Over Time
        </Heading>
        <Line data={data} options={options} height={400} />
      </Box>
    </Box>
  );
};

export default PdfEvaluationAnalytics;
