// src/App.js
import "./App.css";
import Home from "./components/pages/Home";
import { Route, Routes, useLocation } from "react-router-dom";
import ContactUs from "./components/pages/ContactUs";
import FAQs from "./components/pages/FAQs";
import TermsAndConditions from "./components/pages/TermsAndConditions";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import Login from "./components/pages/Login";
import Dashboard from "./components/dashboard/Dashboard";
import Register from "./components/pages/Register";
import Layout from "./components/pages/Layout";
import Analytics from "./components/dashboard/Analytics";
import Profile from "./components/dashboard/Profile";
import Exam from "./components/dashboard/exam/AttemptExam";
import AllAttemptsDone from "./components/dashboard/exam/AllExamAttemptsDone";
import NotFound from "./components/pages/NotFound";
import ForgotPassword from "./components/pages/ForgotPassword";
import NewMockTest from "./components/dashboard/exam/NewMockTest";
import EvaluatePdf from "./components/dashboard/pdfEvaluation/EvaluateNewPdf";
import EvaluationDetails from "./components/dashboard/pdfEvaluation/AllEvaluatedPdfs";
import ImageQuestionProcessor from "./components/dashboard/askImageQuestion/ImageQuestionAnswer";
import ALLImageQuestionAnswers from "./components/dashboard/askImageQuestion/AllImageQuestionsAnswers";
import TalkToAssistantComponent from "./components/dashboard/talkToAI/TalkToAssistant";
import OMRFileEvaluate from "./components/dashboard/omrSheetEvaluation/EvaluateOmrSheet";
import OmrEvaluationList from "./components/dashboard/omrSheetEvaluation/AllEvaluatedOmrs";

const App = () => {
  const location = useLocation();

  // List of routes where the layout should not be rendered
  const noLayoutRoutes = [
    "/dashboard",
    "/dashboard/profile",
    "/dashboard/new-ai-mock-test",
    "/dashboard/evaluate-omr",
    "/dashboard/all-evaluated-omrs",
    "/dashboard/evaluate-pdf",
    "/dashboard/evaluate-pdf-results",
    "/dashboard/ask-question",
    "/dashboard/asked-questions",
    "/dashboard/ai-voice-talk",
    "/dashboard/all-mock-tests",
    "/dashboard/analytics",
    "/dashboard/new-ai-mock-test/exam/start",
  ];

  // Check if the current path matches any in the noLayoutRoutes list
  const isNoLayoutRoute = noLayoutRoutes.includes(location.pathname);

  return (
    <>
      {/* Conditionally render Layout based on current path */}
      {isNoLayoutRoute ? (
        <Routes>
          <Route path="/dashboard" element={<Dashboard />}>
            <Route path="/dashboard/profile" element={<Profile />} />
            <Route
              path="/dashboard/new-ai-mock-test"
              element={<NewMockTest />}
            />
            <Route
              path="/dashboard/new-ai-mock-test/exam/start"
              element={<Exam />}
            />
            <Route
              path="/dashboard/all-mock-tests"
              element={<AllAttemptsDone />}
            />
            <Route
              path="/dashboard/evaluate-omr"
              element={<OMRFileEvaluate />}
            />

            <Route
              path="/dashboard/all-evaluated-omrs"
              element={<OmrEvaluationList />}
            />

            <Route path="/dashboard/evaluate-pdf" element={<EvaluatePdf />} />

            <Route
              path="/dashboard/evaluate-pdf-results"
              element={<EvaluationDetails />}
            />
            <Route
              path="/dashboard/ask-question"
              element={<ImageQuestionProcessor />}
            />
            <Route
              path="/dashboard/asked-questions"
              element={<ALLImageQuestionAnswers />}
            />
            <Route
              path="/dashboard/ai-voice-talk"
              element={<TalkToAssistantComponent />}
            />
            <Route path="/dashboard/analytics" element={<Analytics />} />
          </Route>
        </Routes>
      ) : (
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Layout>
      )}
    </>
  );
};

export default App;
