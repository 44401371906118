import React, { useState, useRef } from "react";
import {
  Button,
  VStack,
  Text,
  useToast,
  IconButton,
  Input,
  HStack,
  Tooltip,
  Select,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { FiUpload, FiTrash2 } from "react-icons/fi";
import axios from "axios";
import { getCookieToken } from "../../../utils/utils";
import { serverBaseUrl } from "../../../constants/Constants";
import { v4 as uuidv4 } from "uuid";

const OMRFileEvaluate = () => {
  const [correctOMRFile, setCorrectOMRFile] = useState(null);
  const [studentsOMRFile, setStudentsOMRFile] = useState(null);
  const [isEvaluating, setIsEvaluating] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [classSection, setClassSection] = useState("");
  const [subjectName, setSubjectName] = useState("");
  const [topicName, setTopicName] = useState("");
  const [educationYear, setEducationYear] = useState("");
  const [totalQuestions, setTotalQuestions] = useState(""); // New state for total questions
  const toast = useToast();

  const correctOMRInputRef = useRef(null);
  const studentsOMRInputRef = useRef(null);

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === "correctOMR") {
      setCorrectOMRFile(files[0]);
    } else if (name === "studentsOMR") {
      setStudentsOMRFile(files[0]);
    }
  };

  const handleDeleteFile = (fileType) => {
    if (fileType === "correctOMR") {
      setCorrectOMRFile(null);
    } else if (fileType === "studentsOMR") {
      setStudentsOMRFile(null);
    }
  };

  const uploadFile = async (file, fileType, uniqueFeId) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("uniqueFeId", uniqueFeId);
    formData.append("fileType", fileType);
    formData.append("orgName", orgName);
    formData.append("classSection", classSection);
    formData.append("subjectName", subjectName);
    formData.append("topicName", topicName);
    formData.append("educationYear", educationYear);
    formData.append("totalQuestions", totalQuestions); // Include total questions

    const token = getCookieToken();

    try {
      const response = await axios.post(`${serverBaseUrl}/omr/text`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${token}`,
        },
      });
      return response?.data?.data?.fileUrl;
    } catch (error) {
      throw new Error("Failed to upload file");
    }
  };

  const handleSubmit = async () => {
    if (
      !correctOMRFile ||
      !studentsOMRFile ||
      !orgName ||
      !classSection ||
      !subjectName ||
      !educationYear ||
      !totalQuestions // Validate total questions
    ) {
      toast({
        title: "Error",
        description:
          "Please fill all the required fields and upload both files before submitting.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsEvaluating(true);

    const uniqueFeId = uuidv4();

    try {
      await uploadFile(correctOMRFile, "correctOMR", uniqueFeId);

      await uploadFile(studentsOMRFile, "studentsOMR", uniqueFeId);

      toast({
        title: "Success",
        description: "Your files have been evaluated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsEvaluating(false);
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsEvaluating(false);
    }
  };

  return (
    <VStack
      spacing={4}
      align="center"
      mt={"5%"}
      mb={"10%"}
      maxW={{ base: "90%", md: "60%", lg: "30%" }}
      mx="auto"
    >
      <FormControl isRequired>
        <FormLabel>Organization/School Name</FormLabel>
        <Input
          placeholder="Organization/School Name"
          value={orgName}
          onChange={(e) => setOrgName(e.target.value)}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Class Section Info</FormLabel>
        <Input
          placeholder="Class Section Name like 10th-B"
          value={classSection}
          onChange={(e) => setClassSection(e.target.value)}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Subjects Info</FormLabel>
        <Input
          placeholder="Subject Name/All Subjects"
          value={subjectName}
          onChange={(e) => setSubjectName(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Topic Info</FormLabel>
        <Input
          placeholder="Topic Name (Optional if applicable)"
          value={topicName}
          onChange={(e) => setTopicName(e.target.value)}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Education Year</FormLabel>
        <Select
          placeholder="Select Education Year"
          value={educationYear}
          onChange={(e) => setEducationYear(e.target.value)}
        >
          {Array.from({ length: 9 }, (_, i) => 2025 + i).map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Total Number of Questions</FormLabel>
        <Input
          placeholder="Enter Total Number of Questions"
          value={totalQuestions}
          onChange={(e) => setTotalQuestions(e.target.value)}
        />
      </FormControl>

      {/* Hidden file inputs */}
      <Input
        type="file"
        name="correctOMR"
        accept="application/pdf"
        ref={correctOMRInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      <Input
        type="file"
        name="studentsOMR"
        accept="application/pdf"
        ref={studentsOMRInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />

      {/* Upload buttons */}
      <VStack spacing={2}>
        <Text>Upload Correct OMR Sheet Pdf</Text>
        <IconButton
          icon={<FiUpload />}
          colorScheme="teal"
          onClick={() => correctOMRInputRef.current.click()}
        />
        {correctOMRFile && (
          <HStack>
            <Tooltip label="Delete selected file">
              <Text>{correctOMRFile.name}</Text>
            </Tooltip>
            <IconButton
              icon={<FiTrash2 />}
              colorScheme="red"
              onClick={() => handleDeleteFile("correctOMR")}
            />
          </HStack>
        )}
      </VStack>

      <VStack spacing={2}>
        <Text>Upload all student's combined OMR Sheets PDF</Text>
        <IconButton
          icon={<FiUpload />}
          colorScheme="teal"
          onClick={() => studentsOMRInputRef.current.click()}
        />
        {studentsOMRFile && (
          <HStack>
            <Tooltip label="Delete selected file">
              <Text>{studentsOMRFile.name}</Text>
            </Tooltip>
            <IconButton
              icon={<FiTrash2 />}
              colorScheme="red"
              onClick={() => handleDeleteFile("studentsOMR")}
            />
          </HStack>
        )}
      </VStack>

      <Button
        colorScheme="teal"
        onClick={handleSubmit}
        isLoading={isEvaluating}
        loadingText="Evaluating"
        isDisabled={!correctOMRFile || !studentsOMRFile || isEvaluating}
      >
        Submit and Evaluate
      </Button>

      {isEvaluating && (
        <Text fontSize="lg" color="gray.500">
          Files are being evaluated, please wait...
        </Text>
      )}
    </VStack>
  );
};

export default OMRFileEvaluate;
