import React from "react";
import {
  Box,
  Heading,
  Text,
  Flex,
  Badge,
  VStack,
  Divider,
  useColorModeValue,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { formatDateStringIST } from "../../../utils/utils";

const EvaluationDetails = () => {
  const boxBg = useColorModeValue("gray.600", "gray.700");
  const highlightBg = useColorModeValue("gray.100", "gray.800");
  const highlightColor = useColorModeValue("gray.800", "white");
  const fetchAllQAsRes = useSelector((state) => state.userPdfUploadQAs);

  if (!fetchAllQAsRes || Object.keys(fetchAllQAsRes).length === 0) {
    return (
      <Text
        fontSize={{ base: "medium", lg: "xxx-large" }}
        fontWeight="bold"
        mt={{ base: 4, lg: 12 }}
        mx="auto"
        px="5%"
      >
        No evaluation data available.
      </Text>
    );
  }

  return (
    <Box
      p={6}
      borderRadius="2xl"
      borderWidth={1}
      boxShadow="xl"
      m="4%"
      maxH="90vh"
      overflowY="auto"
      px={{ base: "2%", lg: "5%" }}
      width="95%"
      mx="auto"
      bgGradient="linear(to-r, gray.700, gray.800)"
    >
      <Heading
        mb={6}
        fontSize={{ base: "md", md: "xl", lg: "3xl" }}
        textAlign="center"
        color="white"
      >
        ALL PDF Evaluation Details Done so far
      </Heading>
      {Object.entries(fetchAllQAsRes).map(([key, data], keyIndex) => {
        const totalUserMarks = data.reduce(
          (acc, item) => acc + item.answerMarks,
          0
        );
        const totalQuestionMarks = data.reduce(
          (acc, item) => acc + item.questionTotalMarks,
          0
        );

        return (
          <Box
            key={key}
            mb={{ base: 4, lg: 8 }}
            p={{ base: 2, lg: 6 }}
            mt={{ base: 12, lg: 24 }}
            borderWidth={1}
            borderRadius="xl"
            boxShadow="md"
            bg={boxBg}
            transition="all 0.3s ease-in-out"
            _hover={{ transform: "scale(1.02)" }}
          >
            <Flex justifyContent="space-between" alignItems="center" mb={4}>
              <Heading size={{ base: "sm", lg: "lg" }} color="teal.300">
                Evaluation for Req ID: {formatDateStringIST(data[0].createdAt)}
              </Heading>
              <Badge
                colorScheme="purple"
                fontSize={{ base: "xx-small", lg: "sm" }}
                px={4}
                py={1}
              >
                {data.length} Questions
              </Badge>
            </Flex>
            <Text color="white" mb={4} fontSize={{ base: "sm", lg: "md" }}>
              Total Marks: {totalUserMarks} / {totalQuestionMarks}
            </Text>
            <VStack spacing={{ base: 1, lg: 6 }} align="start">
              {data.map((item, index) => (
                <Box key={index} w="full">
                  <Box
                    p={4}
                    bg={highlightBg}
                    borderRadius="lg"
                    boxShadow="md"
                    mb={4}
                  >
                    <Text color={highlightColor} mb={2}>
                      <strong>Question {index + 1}:</strong>{" "}
                      {item.question.join(" ")}
                    </Text>
                    <Text color={highlightColor} mb={2}>
                      <strong>User Answer:</strong> {item.userAnswer.join(" ")}
                    </Text>
                    <Text color={highlightColor} mb={2}>
                      <strong>Correct Answer:</strong>{" "}
                      {item.actualCorrectAnswer.join(" ")}
                    </Text>
                    {item.adminCorrectAnswer.length > 0 && (
                      <Text color={highlightColor} mb={2}>
                        <strong>Admin Answer:</strong>{" "}
                        {item.adminCorrectAnswer.join(" ")}
                      </Text>
                    )}
                    <Flex justifyContent="space-between" alignItems="center">
                      <Text color={highlightColor}>
                        <strong>Marks:</strong> {item.answerMarks} /{" "}
                        {item.questionTotalMarks}
                      </Text>
                      <Badge
                        colorScheme={
                          item.answerStatus === "correct" ? "green" : "red"
                        }
                        fontSize={{ base: "xx-small", lg: "sm" }}
                      >
                        {item.answerStatus}
                      </Badge>
                    </Flex>
                  </Box>
                  {index < data.length - 1 && (
                    <Divider borderColor="gray.500" my={4} />
                  )}
                </Box>
              ))}
            </VStack>
          </Box>
        );
      })}
    </Box>
  );
};

export default EvaluationDetails;
