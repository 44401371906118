import React from "react";
import {
  Box,
  Heading,
  List,
  ListItem,
  ListIcon,
  Text,
  VStack,
  useColorModeValue,
  HStack,
  ScaleFade,
} from "@chakra-ui/react";
import { CheckCircleIcon, StarIcon } from "@chakra-ui/icons";

const FeatureList = () => {
  const bg = useColorModeValue("white", "gray.800");
  const boxBg = useColorModeValue("gray.50", "gray.700");
  const headingColor = useColorModeValue("teal.500", "teal.300");
  const iconColor = useColorModeValue("teal.400", "teal.200");
  const specialBoxBg = useColorModeValue("teal.50", "teal.900");
  const specialIconColor = useColorModeValue("yellow.500", "yellow.300");
  const differentBoxBg = useColorModeValue("purple.50", "purple.900");
  const differentIconColor = useColorModeValue("purple.500", "purple.300");

  return (
    <Box
      maxW={{ base: "100%", lg: "50%" }}
      position={"absolute"}
      left={{ base: "5%", md: "17%", lg: "30%" }}
      p={{ base: 2, lg: 6 }}
      mt={{ base: "20%", lg: "2%" }}
      borderRadius="md"
      boxShadow="lg"
      bg={bg}
    >
      <Heading
        as="h2"
        size="xl"
        mb={{ base: 2, lg: 6 }}
        textAlign="center"
        color={headingColor}
      >
        AI Features for you
      </Heading>
      <VStack spacing={6} align="center" p="5%">
        <FeatureItem
          icon={CheckCircleIcon}
          title="Customized Mock Tests"
          description="Generate tailored customized mock tests for your exam to asses
                and improve yourself."
          boxBg={boxBg}
          iconColor={iconColor}
        />
        <FeatureItem
          icon={CheckCircleIcon}
          title="OMR pdf evaluation"
          description="Get your class student OMR's pdf evaluated with our advanced AI
                and detailed analytics on the top of that"
          boxBg={boxBg}
          iconColor={iconColor}
        />
        <FeatureItem
          icon={CheckCircleIcon}
          title="AI-Powered PDF Evaluation"
          description="Get your exam question-answer PDF evaluated with our advanced
                AI."
          boxBg={boxBg}
          iconColor={iconColor}
        />
        <FeatureItem
          icon={CheckCircleIcon}
          title="Step-by-Step Solutions"
          description="Upload any question image and receive a detailed step-by-step solution. Ask any counter questions you may have about the solution."
          boxBg={boxBg}
          iconColor={iconColor}
        />
        <FeatureItem
          icon={CheckCircleIcon}
          title="Interactive Mock Tests"
          description="Speak with our voice assistant to take a voice-based mock viva
                test."
          boxBg={boxBg}
          iconColor={iconColor}
        />
        <FeatureItem
          icon={StarIcon}
          title="360-Degree Analytics"
          description="Get detailed analytics based on your mock tests, pdf submissions, and interactions."
          boxBg={specialBoxBg}
          iconColor={specialIconColor}
          isSpecial
        />
        <FeatureItem
          icon={StarIcon}
          title="More Features"
          description="Stay tuned for more exciting features coming soon."
          boxBg={differentBoxBg}
          iconColor={differentIconColor}
          isSpecial
        />
      </VStack>
    </Box>
  );
};

const FeatureItem = ({
  icon,
  title,
  description,
  boxBg,
  iconColor,
  isSpecial,
}) => (
  <ScaleFade initialScale={0.9} in>
    <Box
      bg={boxBg}
      p={4}
      borderRadius="md"
      boxShadow="md"
      width="100%"
      border={isSpecial ? "2px solid" : ""}
      borderColor={isSpecial ? iconColor : ""}
      transform={isSpecial ? "scale(1.05)" : ""}
      transition="transform 0.3s ease, border-color 0.3s ease"
      _hover={{
        transform: "scale(1.1)",
        borderColor: "transparent",
      }}
    >
      <List spacing={2}>
        <ListItem>
          <HStack>
            <ListIcon as={icon} color={iconColor} />
            <Text as="b" fontSize={isSpecial ? "lg" : "md"}>
              {title}
            </Text>
          </HStack>
        </ListItem>
        <ListItem>
          <Text>{description}</Text>
        </ListItem>
      </List>
    </Box>
  </ScaleFade>
);

export default FeatureList;
