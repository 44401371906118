import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  Text,
  Grid,
  GridItem,
  Circle,
  Tooltip,
  useColorModeValue,
  Button,
  useToast,
} from "@chakra-ui/react";
import {
  FaCheckCircle,
  FaCircle,
  FaTimesCircle,
  FaExclamationCircle,
} from "react-icons/fa";
import { hitBeApi } from "../../../api/api";

// Import your API request function

const QuestionNavigator = ({
  questions,
  exam,
  currentQuestionNumber,
  sectionName,
  currentSectionNumber,
  handleChangeCurrentSectionFromNavigator,
  handleChangeQuestionFromNavigator,
  questionTimeSpent,
  saveAnswer,
  isSubmittingAnswer,
  userAnswer,
  questionVisibility,
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const toast = useToast();

  const statusIcons = {
    answered: <FaCheckCircle color="green" />,
    marked: <FaExclamationCircle color="purple" />,
    attempted: <FaCircle color="gray" />,
    notAnswered: <FaTimesCircle color="red" />,
    markedAndAnswered: <FaCheckCircle color="purple" />,
  };

  const bgBox = useColorModeValue("blue.50", "blue.900");
  const bgInnerBox = useColorModeValue("blue.100", "blue.800");
  const borderColor = useColorModeValue("black", "white");
  const buttonBg = useColorModeValue("white", "gray.700");
  const buttonHoverBg = useColorModeValue("gray.200", "gray.600");
  const currentButtonBg = useColorModeValue("blue.200", "blue.700");
  const currentButtonBorder = useColorModeValue("blue.400", "blue.600");
  const sectionButtonBg = useColorModeValue("#ccc", "gray.600");
  const elapsedQuestionTimeRef = useRef(questionTimeSpent);

  const handleChangeCurrentSection = async (index) => {
    if (index !== currentSectionNumber) {
      let isError = false;
      try {
        await saveAnswer(
          userAnswer,
          questionVisibility,
          elapsedQuestionTimeRef.current
        );
      } catch {
        isError = true;
      }
      if (!isError) {
        handleChangeCurrentSectionFromNavigator(index);
      }
    }
  };

  const handleChangeCurrentQuestion = async (index) => {
    if (index !== currentQuestionNumber) {
      let isError = false;
      try {
        await saveAnswer(
          userAnswer,
          questionVisibility,
          elapsedQuestionTimeRef.current
        );
      } catch (e) {
        isError = true;
      }
      if (!isError) {
        handleChangeQuestionFromNavigator(index);
      }
    }
  };

  const downloadExam = async () => {
    try {
      setIsDownloading(true);
      const response = await hitBeApi(`exams/do/download/${exam?._id}`, "GET");

      if (response.success) {
        const link = document.createElement("a");
        link.href = response.data.fileUrl;
        link.target = "_blank";
        link.rel = "noopener noreferrer";
        link.download = "exam_attempt.pdf";
        link.click();
        toast({
          title: "Download successful.",
          description: "The exam has been downloaded successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Download failed.",
          description: response.message || "Failed to download the exam.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "An error occurred.",
        description: "Unable to download the exam. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    const startTime = Date.now();
    const timerInterval = setInterval(() => {
      const currentTime = Date.now();
      const elapsed = currentTime - startTime;
      elapsedQuestionTimeRef.current = elapsed + questionTimeSpent;
    }, 1000);
    return () => {
      clearInterval(timerInterval);
    };
  }, [currentSectionNumber, currentQuestionNumber, questionTimeSpent]);

  return (
    <>
      <Flex as="nav" mb={1} wrap="wrap" p={4}>
        {exam?.sections?.map((section, index) => (
          <Button
            key={index}
            onClick={() => handleChangeCurrentSection(index)}
            bg={index === currentSectionNumber ? sectionButtonBg : buttonBg}
            _hover={{ bg: buttonHoverBg }}
            disabled={isSubmittingAnswer}
            mr={2}
            mt={2}
          >
            {section.title}
          </Button>
        ))}
      </Flex>
      <Box
        bg={bgBox}
        p={8}
        borderRadius="md"
        width={{ base: "100vw", lg: "70vw" }}
        overflowY={"scroll"}
      >
        <Flex justifyContent="space-between" mb={8}>
          <Button
            onClick={downloadExam}
            isLoading={isDownloading}
            loadingText="Downloading"
            colorScheme="blue"
            size="sm"
          >
            Download Exam
          </Button>
        </Flex>
        <Flex
          justifyContent="space-between"
          mb={4}
          fontSize={{ base: "xx-small", lg: "small" }}
        >
          <Flex alignItems="center">
            <Circle size="20px" bg="green.500" mr={2} />
            <Text>Answered</Text>
          </Flex>
          <Flex alignItems="center" ml={2}>
            <Circle size="20px" bg="purple.500" mr={2} />
            <Text>Marked</Text>
          </Flex>
        </Flex>
        <Flex
          justifyContent="space-between"
          mb={4}
          fontSize={{ base: "xx-small", lg: "small" }}
        >
          <Flex alignItems="center">
            <Circle size="20px" bg="gray.500" mr={2} />
            <Text>Not Visited</Text>
          </Flex>
          <Flex alignItems="center" ml={2}>
            <Circle size="20px" bg="red.500" mr={2} />
            <Text>Not Answered (Attempted)</Text>
          </Flex>
        </Flex>
        <Flex
          justifyContent="space-between"
          mb={4}
          fontSize={{ base: "xx-small", lg: "small" }}
        >
          <Flex alignItems="center">
            <Circle size="20px" bg="purple.700" mr={2} />
            <Text>Marked and Answered</Text>
          </Flex>
        </Flex>
        <Box bg={bgInnerBox} p={4} borderRadius="md" mb={4} mt={6}>
          <Text fontWeight="bold">SECTION: {sectionName}</Text>
        </Box>
        <Grid
          templateColumns={{ base: "repeat(4, 1fr)", lg: "repeat(10, 1fr)" }}
          gap={3}
        >
          {questions.map((question, index) => (
            <Tooltip
              key={index}
              label={question.visibility.replace(/([A-Z])/g, " $1").trim()}
              placement="top"
            >
              <GridItem>
                <Box
                  as="button"
                  w="100%"
                  h="60px"
                  display="flex"
                  alignItems="center"
                  onClick={() => handleChangeCurrentQuestion(index)}
                  justifyContent="center"
                  border="1px solid"
                  borderColor={borderColor}
                  disabled={isSubmittingAnswer}
                  borderRadius="md"
                  bg={
                    currentQuestionNumber === index ? currentButtonBg : buttonBg
                  }
                  _hover={{ bg: buttonHoverBg }}
                  position="relative"
                >
                  {currentQuestionNumber === index && (
                    <Box
                      position="absolute"
                      top="0"
                      left="0"
                      right="0"
                      bottom="0"
                      border={`2px solid ${currentButtonBorder}`}
                      borderRadius="md"
                    />
                  )}
                  <Text fontWeight="bold" zIndex="1">
                    {index + 1}
                  </Text>
                  <Box position="absolute" top="2px" right="2px">
                    {statusIcons[question.visibility]}
                  </Box>
                </Box>
              </GridItem>
            </Tooltip>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default QuestionNavigator;
