import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Text,
  Image,
  Spinner,
  VStack,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import renderMathInElement from "katex/contrib/auto-render";
import { useDispatch, useSelector } from "react-redux";
import { hitBeApi } from "../../../api/api";

const ALLImageQuestionAnswers = () => {
  const userImageAskData = useSelector((state) => state.userImageAskData);
  const [data, setData] = useState(userImageAskData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const toast = useToast();
  const fetchImageQuestionAnswersCalled = useRef(false);
  const dispatch = useDispatch();

  // Determine colors based on the current color mode
  const boxBgColor = useColorModeValue("gray.50", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const textColor = useColorModeValue("gray.800", "gray.100");
  const accentColor = useColorModeValue("teal.500", "teal.300");

  const renderLatex = (text) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = text;
    renderMathInElement(tempDiv, {
      delimiters: [
        { left: "\\(", right: "\\)", display: false },
        { left: "\\[", right: "\\]", display: true },
      ],
    });
    return tempDiv.innerHTML;
  };

  const formatText = (text) => {
    const textStr = String(text);
    const formattedText = textStr
      .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>") // Bold text
      .replace(/\n/g, "<br>"); // New lines

    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = formattedText;
    renderMathInElement(tempDiv, {
      delimiters: [
        { left: "\\(", right: "\\)", display: false },
        { left: "\\[", right: "\\]", display: true },
      ],
    });
    return tempDiv.innerHTML;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (fetchImageQuestionAnswersCalled.current) {
        return;
      }
      fetchImageQuestionAnswersCalled.current = true;
      try {
        const result = await hitBeApi("image", "GET");
        setData(result.data.imageQuestionAnswers);
        dispatch({
          type: "SET_USER_IMAGE_ASK_DATA",
          value: result.data.imageQuestionAnswers,
        });
        toast({
          title: "Data fetched successfully.",
          description: "Image question answers have been loaded.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } catch (err) {
        setError(err.message);
        toast({
          title: "Error fetching data.",
          description: `An error occurred: ${err.message}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    if (userImageAskData && Object.keys(userImageAskData).length !== 0) {
      setLoading(false);
    } else {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toast]);

  if (loading) return <Spinner size="xl" />;
  if (error) return <Text color="red.500">Error: {error}</Text>;

  return (
    <VStack spacing={4} align="start" mb="10%">
      <Text
        fontSize={{ base: "medium", lg: "xxx-large" }}
        fontWeight="bold"
        mt={{ base: 4, lg: 12 }}
        mx="auto"
        color={textColor}
        px="5%"
      >
        All Questions asked with images
      </Text>
      {data.length === 0 && (
        <Text
          fontSize="large"
          fontWeight="bold"
          mt={{ base: 4, lg: 12 }}
          mx="auto"
          color={textColor}
          px="5%"
        >
          No asked questions
        </Text>
      )}
      {data.map((imageQuestion, idx) => (
        <Box
          key={imageQuestion._id}
          p={4}
          bg={boxBgColor}
          borderWidth={1}
          borderColor={borderColor}
          borderRadius="md"
          boxShadow="md"
          width="90%"
          mx="auto"
          mt="5%"
        >
          <Text fontSize="xx-large" fontWeight="bold" mb={2} color={textColor}>
            Image Question Answer {idx + 1}:
          </Text>
          <Image
            src={imageQuestion.imageUrl}
            alt="Test Image"
            objectFit={"fill"}
            mb={4}
          />
          <Text
            mb={2}
            color={textColor}
            whiteSpace="pre-line"
            fontSize={{ base: "small", md: "medium", lg: "large" }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: formatText(renderLatex(imageQuestion.answerText)),
              }}
            />
          </Text>
          <Text
            fontSize="x-large"
            fontWeight="bold"
            mb={2}
            mt={8}
            color={textColor}
          >
            Cross Questions:
          </Text>
          {imageQuestion.crossQuestions.length > 0 ? (
            imageQuestion.crossQuestions.map((cq, index) => (
              <Box
                key={cq._id}
                p={3}
                bg={boxBgColor}
                borderWidth={1}
                borderColor={borderColor}
                borderRadius="md"
                boxShadow="sm"
                mb={2}
              >
                <Text
                  fontSize="large"
                  fontWeight="bold"
                  mb={1}
                  color={textColor}
                >
                  Cross-Question {index + 1}:
                </Text>
                <Text mb={1} color={accentColor}>
                  {cq.crossQuestionText}
                </Text>
                <Text
                  color={textColor}
                  fontSize={{ base: "x-small", lg: "large" }}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: formatText(renderLatex(cq.crossQuestionAnswer)),
                    }}
                  />
                </Text>
              </Box>
            ))
          ) : (
            <Text color={textColor}>No cross questions available.</Text>
          )}
        </Box>
      ))}
    </VStack>
  );
};

export default ALLImageQuestionAnswers;
