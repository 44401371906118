import { appState } from "../state/state";

export const appReducer = (state = appState, action) => {
  switch (action.type) {
    case "SET_EXAM": {
      return {
        ...state,
        exam: action.value,
      };
    }
    case "SET_ATTEMPT": {
      return {
        ...state,
        attempt: action.value,
      };
    }
    case "SET_ATTEMPTS": {
      return {
        ...state,
        attempts: action.value || [],
      };
    }
    case "SET_ALL_ATTEMPTS_MAP": {
      const attemptId = action.value?._id;
      return {
        ...state,
        attemptsMap: {
          ...state.attemptsMap,
          [attemptId]: action.value,
        },
      };
    }
    case "SET_CURRENT_QUESTION": {
      return {
        ...state,
        currentQuestion: action.value,
      };
    }
    case "SET_VOICE_CONVERSATIONS": {
      return {
        ...state,
        userVoiceConversations: action.value,
      };
    }
    case "SET_USER_PDF_UPLOADS": {
      return {
        ...state,
        userPdfUploadQAs: action.value,
      };
    }
    case "SET_USER_OMR_UPLOADS": {
      return {
        ...state,
        userOmrUploads: action.value,
      };
    }
    case "SET_USER_IMAGE_ASK_DATA": {
      return {
        ...state,
        userImageAskData: action.value,
      };
    }
    case "SET_CURRENT_QUESTION_NUMBER": {
      return {
        ...state,
        currentQuestionNumber: action.value,
      };
    }
    case "SET_CURRENT_SECTION_NUMBER": {
      return {
        ...state,
        currentSectionNumber: action.value,
      };
    }
    case "ADD_QUESTION_DETAILS": {
      const questionId = action.value?._id;
      return {
        ...state,
        allQuestionsMap: {
          ...state.allQuestionsMap,
          [questionId]: action.value,
        },
      };
    }
    case "SET_EXAM_ID": {
      return {
        ...state,
        examId: action.value,
      };
    }
    case "SET_SCORE": {
      return {
        ...state,
        score: action.value,
      };
    }
    case "SET_ATTEMPT_ID": {
      return {
        ...state,
        attemptId: action.value,
      };
    }
    case "SET_USER_ID": {
      return {
        ...state,
        userId: action.value,
      };
    }
    case "SET_PROFILE": {
      return {
        ...state,
        profile: action.value,
      };
    }
    case "SET_ANALYTICS": {
      return {
        ...state,
        analytics: action.value,
      };
    }
    default:
      return state;
  }
};
