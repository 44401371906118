import React, { useEffect, useRef, useState } from "react";
import QuestionNavigator from "./QuestionNavigator";
import {
  Box,
  Button,
  Heading,
  Text,
  Tooltip,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import QuestionComponent from "./Question";
import { useSelector, useDispatch } from "react-redux";
import { hitBeApi } from "../../../api/api";
import { useNavigate } from "react-router-dom";
import { getFormattedTime } from "../../../utils/utils";
import ExamSummary from "./AttemptSummary";

const Exam = () => {
  const navigate = useNavigate();
  const examId = useSelector((state) => state.examId);
  const attemptIdFromState = useSelector((state) => state.attemptId);
  const currentQuestion = useSelector((state) => state.currentQuestion);
  const exam = useSelector((state) => state.exam);
  const currentSectionNumber = useSelector(
    (state) => state.currentSectionNumber
  );
  const currentQuestionNumber = useSelector(
    (state) => state.currentQuestionNumber
  );
  const attempt = useSelector((state) => state.attempt);
  const allQuestionsMap = useSelector((state) => state.allQuestionsMap);
  const score = useSelector((state) => state.score);
  const elapsedAttemptTimeRef = useRef(attempt.time);
  const [elapsedAttemptTime, setElapsedAttemptTime] = useState(attempt.time);

  const dispatch = useDispatch();
  const toast = useToast();
  const createExamAttemptCalled = useRef(false);
  const fetchExamDetailsCalled = useRef(false);
  const fetchQuestionsDetailsCalled = useRef(false);
  const fetchExamScoreCalled = useRef(false);
  const [isSubmittingAnswer, setIsSubmittingAnswer] = useState(false);

  const headerBg = useColorModeValue("gray.100", "gray.700");

  const handleChangeCurrentSectionNumber = async (newSectionIndex) => {
    if (isSubmittingAnswer) {
      return;
    }
    dispatch({
      type: "SET_CURRENT_SECTION_NUMBER",
      value: newSectionIndex % attempt.sections.length,
    });
    dispatch({
      type: "SET_CURRENT_QUESTION_NUMBER",
      value: 0,
    });
  };

  const handleChangeCurrentQuestionNumber = async (newQuestionIndex) => {
    if (newQuestionIndex < 0) {
      newQuestionIndex =
        exam.sections[currentSectionNumber].questionIds.length - 1;
    }
    if (isSubmittingAnswer) {
      return;
    }
    dispatch({
      type: "SET_CURRENT_QUESTION_NUMBER",
      value:
        newQuestionIndex %
        exam.sections[currentSectionNumber].questionIds.length,
    });
  };

  const fetchExamDetails = async () => {
    if (fetchExamDetailsCalled.current) return;
    fetchExamDetailsCalled.current = true;
    try {
      const response = await hitBeApi(`exams/ongoing`, "GET");
      const { examDetails } = response.data;
      if (!examDetails || Object.keys(examDetails).length === 0) {
        toast({
          title: "Success",
          description: "No ongoing exam found , please generate a new exam.",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        navigate("/dashboard/new-ai-mock-test");
      } else {
        dispatch({ type: "SET_EXAM", value: examDetails });
        dispatch({ type: "SET_EXAM_ID", value: examDetails?._id });
        toast({
          title: "Exam details fetched.",
          description: "Your exam details are successfully fetched.",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error fetching exam details:", error);
      navigate("/dashboard/new-ai-mock-test");
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const createAttempt = async () => {
    if (createExamAttemptCalled.current) return;
    createExamAttemptCalled.current = true;
    try {
      const response = await hitBeApi("exams/attempt/create", "POST", {
        examId,
      });
      const { attemptDetails } = response.data;
      dispatch({ type: "SET_ATTEMPT_ID", value: attemptDetails?._id });
      dispatch({ type: "SET_ATTEMPT", value: attemptDetails });
      toast({
        title: "Exam attempt start.",
        description: "Your exam attempt is successfully started.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error creating attempt:", error);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const fetchQuestionDetails = async () => {
    if (fetchQuestionsDetailsCalled.current) return;
    fetchQuestionsDetailsCalled.current = true;
    try {
      const questionId =
        exam?.sections[currentSectionNumber]?.questionIds[
          currentQuestionNumber
        ];
      const response = await hitBeApi(`exam/question/${questionId}`, "GET");
      const { questionDetails } = response.data;
      dispatch({ type: "ADD_QUESTION_DETAILS", value: questionDetails });
      dispatch({ type: "SET_CURRENT_QUESTION", value: questionDetails });
      toast({
        title: "Question details fetched.",
        description: "Your question details are successfully fetched.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error fetching question details:", error);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const fetchAttemptScoreDetails = async () => {
    if (fetchExamScoreCalled.current) return;
    fetchExamScoreCalled.current = true;
    try {
      const response = await hitBeApi(
        `exams/do/score/${attemptIdFromState}`,
        "GET"
      );
      const { score } = response.data;
      dispatch({ type: "SET_SCORE", value: score });

      toast({
        title: "Exam completed.",
        description: "Score details are fetched successfully.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error fetching score details:", error);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (attempt.allowedTime - elapsedAttemptTime <= 0 && score === 0) {
      fetchAttemptScoreDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attempt.allowedTime, elapsedAttemptTime]);

  const submitCurrentAttemptDetails = async (
    userAnswer = null,
    visibility = null,
    questionTimeSpent = null,
    totalAttemptTime = null
  ) => {
    try {
      if (isSubmittingAnswer) {
        return;
      }
      const currentQuestionId =
        exam?.sections[currentSectionNumber].questionIds[currentQuestionNumber];
      setIsSubmittingAnswer(true);
      let visibilityValue = visibility || "attempted";
      if (visibility === "notVisited") {
        visibilityValue = "attempted";
      }
      const response = await hitBeApi(
        `exam/question/${attemptIdFromState}/${currentQuestionId}/submit`,
        "POST",
        {
          userAnswer,
          visibility: visibilityValue,
          questionTimeSpent: questionTimeSpent,
          sectionIndex: currentSectionNumber,
          totalAttemptTimeSpent: totalAttemptTime || elapsedAttemptTime,
        }
      );
      const { newAttemptDetails } = response.data;
      dispatch({ type: "SET_ATTEMPT", value: newAttemptDetails });
      if (userAnswer) {
        toast({
          title: "Success.",
          description: "Your answer is submitted successfully.",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
      setIsSubmittingAnswer(false);
    } catch (error) {
      console.error("Error submitting answer:", error);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      setIsSubmittingAnswer(false);
    }
  };

  useEffect(() => {
    const startTime = Date.now();
    const timerInterval = setInterval(() => {
      const currentTime = Date.now();
      const elapsed = currentTime - startTime;
      elapsedAttemptTimeRef.current = elapsed + attempt.time;
      setElapsedAttemptTime(elapsedAttemptTimeRef.current); // Update state to trigger re-render
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attempt]);

  useEffect(() => {
    if (!examId || Object.keys(exam).length === 0) fetchExamDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examId, exam]);

  useEffect(() => {
    if (examId && exam && !attemptIdFromState) createAttempt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examId, exam, attemptIdFromState]);

  useEffect(() => {
    if (exam && Object.keys(exam).length !== 0) {
      const questionId =
        exam?.sections[currentSectionNumber]?.questionIds[
          currentQuestionNumber
        ];
      if (allQuestionsMap !== undefined && allQuestionsMap[questionId]) {
        const thisQuestionFormState = allQuestionsMap[questionId];
        dispatch({
          type: "SET_CURRENT_QUESTION",
          value: thisQuestionFormState,
        });
      } else {
        fetchQuestionsDetailsCalled.current = false;
        fetchQuestionDetails();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestionNumber, currentSectionNumber, exam, allQuestionsMap]);

  if (attempt.allowedTime - elapsedAttemptTime <= 0) {
    return (
      <ExamSummary exam={exam} attempt={attempt} score={score}></ExamSummary>
    );
  }

  return (
    <Box pt={{ base: "12%", lg: "1%" }} px="auto">
      {Object.keys(exam).length !== 0 &&
        currentQuestion &&
        attempt.sections && (
          <>
            <Box
              as="header"
              bg={headerBg}
              p={{ base: 4, lg: 4 }}
              mb={4}
              width={{ base: "100vw", lg: "70vw" }}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Box>
                <Heading as="h1" size={{ base: "sm", lg: "lg" }} mb={2}>
                  {exam.title}
                </Heading>
                <Text>
                  Time Left:{" "}
                  {getFormattedTime(attempt.allowedTime - elapsedAttemptTime)}
                </Text>
              </Box>
              <Tooltip label="Exam can be submitted only after 3 minutes of start of the exam or after exam time completes, whichever is earlier">
                <Button
                  colorScheme="blue"
                  fontSize={{ base: "sm", lg: "lg" }}
                  isDisabled={
                    elapsedAttemptTime / 1000 < 180 || isSubmittingAnswer
                  }
                  onClick={async () =>
                    await submitCurrentAttemptDetails(
                      null,
                      null,
                      null,
                      attempt.allowedTime
                    )
                  }
                >
                  Complete And Submit Exam
                </Button>
              </Tooltip>
            </Box>
            <QuestionNavigator
              questions={
                attempt?.sections[currentSectionNumber].questions || []
              }
              exam={exam}
              currentQuestionNumber={currentQuestionNumber}
              currentSectionNumber={currentSectionNumber}
              sectionName={exam?.sections[currentSectionNumber]?.title}
              handleChangeQuestionFromNavigator={
                handleChangeCurrentQuestionNumber
              }
              handleChangeCurrentSectionFromNavigator={
                handleChangeCurrentSectionNumber
              }
              questionTimeSpent={
                attempt?.sections
                  ? attempt?.sections[currentSectionNumber]?.questions[
                      currentQuestionNumber
                    ]?.time
                  : 0
              }
              userAnswer={
                attempt?.sections
                  ? attempt?.sections[currentSectionNumber]?.questions[
                      currentQuestionNumber
                    ].userAnswer
                  : ""
              }
              questionVisibility={
                attempt?.sections
                  ? attempt?.sections[currentSectionNumber]?.questions[
                      currentQuestionNumber
                    ].visibility
                  : ""
              }
              isSubmittingAnswer={isSubmittingAnswer}
              saveAnswer={submitCurrentAttemptDetails}
            />
            <Box mt={4}>
              <QuestionComponent
                questionText={currentQuestion.questionText}
                questionPassage={currentQuestion.passage}
                questionType={currentQuestion.questionType}
                questionNumber={currentQuestionNumber + 1}
                options={currentQuestion.options}
                images={currentQuestion.images}
                marksPositive={currentQuestion.positiveMarks}
                marksNegative={currentQuestion.negativeMarks}
                onPreviousClick={() =>
                  handleChangeCurrentQuestionNumber(currentQuestionNumber - 1)
                }
                onNextClick={() =>
                  handleChangeCurrentQuestionNumber(currentQuestionNumber + 1)
                }
                saveAnswer={submitCurrentAttemptDetails}
                userAnswer={
                  attempt?.sections
                    ? attempt?.sections[currentSectionNumber]?.questions[
                        currentQuestionNumber
                      ].userAnswer
                    : ""
                }
                totalCurrentSectionQuestions={
                  attempt?.sections
                    ? attempt?.sections[currentSectionNumber]?.questions.length
                    : 0
                }
                isSubmittingAnswer={isSubmittingAnswer}
                questionTimeSpent={
                  attempt?.sections
                    ? attempt?.sections[currentSectionNumber]?.questions[
                        currentQuestionNumber
                      ].time
                    : 0
                }
                totalExamAttemptTimeRemaining={
                  attempt.allowedTime - elapsedAttemptTime
                }
              />
            </Box>
          </>
        )}
    </Box>
  );
};

export default Exam;
