import { Box } from "@chakra-ui/react";
import React from "react";
import AttemptsAnalytics from "./exam/ExamAttemptsAnalytics";
import { useSelector } from "react-redux";
import PdfEvaluationAnalytics from "./pdfEvaluation/PdfEvaluationsAnalytics";

function Analytics() {
  const attempts = useSelector((state) => state.attempts);
  const userPdfUploadQAs = useSelector((state) => state.userPdfUploadQAs);

  return (
    <Box>
      <AttemptsAnalytics attempts={attempts}></AttemptsAnalytics>
      <PdfEvaluationAnalytics
        evaluationResults={userPdfUploadQAs}
      ></PdfEvaluationAnalytics>
    </Box>
  );
}

export default Analytics;
