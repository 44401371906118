import React, { useState } from "react";
import {
  Box,
  VStack,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  Heading,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

const NewMockTest = () => {
  const [queries, setQueries] = useState([
    "Create a AI Mock Test with 100 full syllabus objective questions of JEE Advanced which has equal weightage of all subjects",
    "Create exam with 50 topic-wise objective questions of NEET Biology",
    "Generate 75 subject-wise objective questions of UPSC General Studies",
    "Create mock test with 60 full syllabus objective questions of CAT with a mix of Quant and Verbal",
    "Take a 40 objective questions exam for NDA Mathematics section",
  ]);

  const handleQueryChange = (index, value) => {
    const newQueries = [...queries];
    newQueries[index] = value;
    setQueries(newQueries);
  };

  return (
    <Box
      py={4}
      px={8}
      maxW={{ base: "100%", lg: "80%" }}
      mx="auto"
      pb="0px"
      id="exams"
      mb="10%"
    >
      <Heading as="h1" size="xl" mb={4} textAlign="center" color="teal.500">
        Explore Our AI Generated Mock Tests
      </Heading>
      <VStack spacing={4} mt={24}>
        {queries.map((query, index) => (
          <Box key={index} width="100%">
            <InputGroup>
              <InputLeftElement pointerEvents="none" p="10">
                <Icon as={SearchIcon} color="gray.300" />
              </InputLeftElement>
              <Input
                value={query}
                onChange={(e) => handleQueryChange(index, e.target.value)}
                placeholder="Enter your query"
                mb={2}
                p="10"
                pl={20} // Padding left to make space for the icon
                _placeholder={{ color: "gray.400" }}
              />
            </InputGroup>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default NewMockTest;
