import React from "react";
import {
  Box,
  Flex,
  Link,
  Button,
  Stack,
  Icon,
  useDisclosure,
  Slide,
  useColorMode,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";
import {
  FaHome,
  FaQuestionCircle,
  FaSignInAlt,
  FaCogs,
  FaInfoCircle,
  FaBars,
  FaTimes,
  FaMoon,
  FaSun,
} from "react-icons/fa";

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();
  const bg = useColorModeValue("white", "gray.800");
  const color = useColorModeValue("blue.500", "blue.200");
  const overlayBg = useColorModeValue("blackAlpha.600", "blackAlpha.800");

  // Close navbar when clicking outside
  const handleOverlayClick = () => {
    if (isOpen) {
      onClose();
    }
  };

  return (
    <>
      {/* Navbar Toggle Button for Mobile */}
      <Button
        position="fixed"
        top={4}
        right={4}
        zIndex={1001}
        colorScheme="teal.500"
        onClick={onOpen}
        display={{ base: "block", lg: "block" }}
      >
        <Icon as={FaBars} boxSize={6} />
      </Button>

      {/* Overlay for reducing opacity */}
      {isOpen && (
        <Box
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height="100%"
          bg={overlayBg}
          opacity={0.8}
          zIndex={1000}
          onClick={handleOverlayClick}
        />
      )}

      {/* Navbar for Desktop */}
      <Slide direction="right" in={isOpen} style={{ zIndex: 1002 }}>
        <Box
          pos="fixed"
          top={0}
          right={0}
          h="100vh"
          w={{ base: "100%", md: "300px" }}
          bg={bg}
          boxShadow="lg"
          p={4}
        >
          <Stack p={4} spacing={6}>
            <Flex alignItems="center" justify="space-between" mb={4}>
              <Link href="/">
                <Button
                  variant="link"
                  color={color}
                  fontSize="lg"
                  _hover={{ textDecoration: "none", color: "blue.400" }}
                >
                  <Icon as={FaHome} boxSize={6} />
                </Button>
              </Link>
              <Button
                onClick={toggleColorMode}
                variant="ghost"
                colorScheme="blue"
              >
                {colorMode === "light" ? <FaMoon /> : <FaSun />}
              </Button>
              <Button onClick={onClose} variant="ghost" color={color}>
                <Icon as={FaTimes} boxSize={6} />
              </Button>
            </Flex>
            <Stack spacing={4}>
              <Link
                href="/contact-us"
                _hover={{ textDecoration: "none", color: "blue.400" }}
              >
                <Flex align="center">
                  <Icon as={FaQuestionCircle} boxSize={5} />
                  <Text ml={2}>Contact Us</Text>
                </Flex>
              </Link>
              <Link
                href="/faqs"
                _hover={{ textDecoration: "none", color: "blue.400" }}
              >
                <Flex align="center">
                  <Icon as={FaInfoCircle} boxSize={5} />
                  <Text ml={2}>FAQs</Text>
                </Flex>
              </Link>
              <Link
                href="/terms-and-conditions"
                _hover={{ textDecoration: "none", color: "blue.400" }}
              >
                <Flex align="center">
                  <Icon as={FaCogs} boxSize={5} />
                  <Text ml={2}>Terms and Conditions</Text>
                </Flex>
              </Link>
              <Link
                href="/privacy-policy"
                _hover={{ textDecoration: "none", color: "blue.400" }}
              >
                <Flex align="center">
                  <Icon as={FaQuestionCircle} boxSize={5} />
                  <Text ml={2}>Privacy Policy</Text>
                </Flex>
              </Link>
              <Link
                href="/login"
                _hover={{ textDecoration: "none", color: "blue.400" }}
              >
                <Flex align="center">
                  <Icon as={FaSignInAlt} boxSize={5} />
                  <Text ml={2}>Login</Text>
                </Flex>
              </Link>
            </Stack>
          </Stack>
        </Box>
      </Slide>
    </>
  );
};

export default Navbar;
