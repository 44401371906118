import React from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { hitBeApi } from "../../api/api";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Example of form data handling
    const formData = new FormData(event.target);
    const data = {
      name: formData.get("name"),
      email: formData.get("email"),
      phoneNumber: formData.get("phoneNumber"),
      message: formData.get("message"),
    };

    try {
      const response = await hitBeApi("contact-us", "POST", data);
      if (response.success) {
        toast({
          title: "Message sent.",
          description: "We will get back to you shortly.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        navigate("/");
      } else {
        toast({
          title: "Some thing went wrong.",
          description: "Please try again later",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error.",
        description: error.message,
        status: "error",
        duration: 8000,
        isClosable: true,
      });
    }
  };

  const bgColor = useColorModeValue("gray.50", "gray.800");
  const formBgColor = useColorModeValue("white", "gray.700");
  const textColor = useColorModeValue("gray.700", "whiteAlpha.900");

  return (
    <Box
      py={20}
      px={8}
      bg={bgColor}
      id="contact-us"
      paddingTop={{ base: "25%", lg: "10%" }}
    >
      <VStack spacing={8} align="center">
        <Heading as="h2" size="xl" mb={4} color={textColor}>
          Contact Us
        </Heading>
        <Text fontSize="lg" textAlign="center" color={textColor}>
          Have questions or feedback? Reach out to us and we will get back to
          you as soon as possible.
        </Text>
        <Box
          as="form"
          width={{ base: "100%", md: "50%" }}
          bg={formBgColor}
          p={8}
          boxShadow="md"
          borderRadius="md"
          onSubmit={handleSubmit}
        >
          <VStack spacing={4} align="stretch">
            <FormControl id="name" isRequired>
              <FormLabel color={textColor}>Name</FormLabel>
              <Input name="name" placeholder="Your Name" />
            </FormControl>
            <FormControl id="email" isRequired>
              <FormLabel color={textColor}>Email</FormLabel>
              <Input name="email" type="email" placeholder="Your Email" />
            </FormControl>
            <FormControl id="phoneNumber">
              <FormLabel color={textColor}>Phone Number</FormLabel>
              <Input
                name="phoneNumber"
                type="tel"
                placeholder="Your Phone Number"
              />
            </FormControl>
            <FormControl id="message" isRequired>
              <FormLabel color={textColor}>Message</FormLabel>
              <Textarea name="message" placeholder="Your Message" rows={6} />
            </FormControl>
            <Button type="submit" colorScheme="teal" size="lg">
              Send Message
            </Button>
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
};

export default ContactUs;
